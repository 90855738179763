import React from 'react';
import Button from '../Button';

// Styles
import * as s from './styles';

// Copy
import footerCopy from '../../copy/footer';
import styled from 'styled-components';


const MobileSpacer = styled.div`
  @media (max-width: 780px) {
    height: 16px;
  }
`;

const Footer = ({pageKey}) => {
  return (
    <s.StyledFooter>
      <s.FooterWrapper>
        <s.FooterUtils>
          <s.FooterUtilsColumn>
            <s.FooterLogoLink to="/">
            <MobileSpacer />
                <img src={footerCopy.footerLogo.img} alt={footerCopy.footerLogo.alt} />
            </s.FooterLogoLink>
            <MobileSpacer />
            {footerCopy.footerUtilLinks.patientSite.keys.includes(pageKey) &&
              <s.ButtonRow>
                <Button
                  variant='tertiary'
                  theme="light"
                  to={ footerCopy.footerUtilLinks.patientSite.href }
                >
                  { footerCopy.footerUtilLinks.patientSite.copy }
                </Button>
              </s.ButtonRow>
            }
            {footerCopy.footerUtilLinks.contactUs.keys.includes(pageKey) &&
              <Button
                variant='tertiary'
                theme="light"
                to={ footerCopy.footerUtilLinks.contactUs.href }
              >
                { footerCopy.footerUtilLinks.contactUs.copy }
              </Button>
            }
          </s.FooterUtilsColumn>

          <s.FooterUtilsColumn>
            { footerCopy.footerSiteLinks.col1.filter(footerItem => footerItem.keys.includes(pageKey)).map((footerItem, i) => (
              <s.FooterLink
                key={i}
                to={footerItem.href}
              >
                { footerItem.label }
              </s.FooterLink>
            ))}
          </s.FooterUtilsColumn>

          <s.FooterUtilsColumn>
            { footerCopy.footerSiteLinks.col2.filter(footerItem => footerItem.keys.includes(pageKey)).map((footerItem, i) => (
              <s.FooterLink
                key={i}
                to={footerItem.href}
              >
                { footerItem.label }
              </s.FooterLink>
            ))}
          </s.FooterUtilsColumn>

          <s.FooterUtilsColumn>
            { footerCopy.footerSiteLinks.col3.filter(footerItem => footerItem.keys.includes(pageKey)).map((footerItem, i) => (
              <s.FooterLink
                key={i}
                to={footerItem.href}
              >
                { footerItem.label }
              </s.FooterLink>
            ))}
          </s.FooterUtilsColumn>

        </s.FooterUtils>
        <s.FooterUtilsSublinks>
            <s.SublinkBackground>
              { footerCopy.subLinks.filter(footerItem => footerItem.keys.includes(pageKey)).map((footerItem, i) => (
                <s.FooterSublink
                  key={i}
                  href={footerItem.href}
                  target={footerItem.target ? footerItem.target : '_blank'}
                  className={footerItem.class ? footerItem.class : false}
                >
                  { footerItem.copy }
                </s.FooterSublink>
              ))}
            </s.SublinkBackground>
        </s.FooterUtilsSublinks>
      </s.FooterWrapper>

      <s.StyledBottomFooter>
        <s.BottomFooterWrapper>

          <s.BottomFooterLeft>
            <span id="qa-copyright">
            {footerCopy.footerBottom.left[0].copy}

            </span>
            <s.BottomFooterSubcopy id="qa-jobcode">
              {footerCopy.footerBottom.left.filter(footerItem => footerItem.keys.includes(pageKey)).map((footerItem, i) => (
                footerItem.subcopy
              ))}
            </s.BottomFooterSubcopy>
          </s.BottomFooterLeft>

          <s.BottomFooterRight>
            <s.AccessibilityLink
              href={footerCopy.footerBottom.accessibility.href}
              target="_blank"
              rel="noreferrer"
            >
              <img className="img-accessibility" src={footerCopy.footerBottom.accessibility.img} alt={footerCopy.footerBottom.accessibility.alt} />
            </s.AccessibilityLink>
            <img className="img-merck" src={footerCopy.footerBottom.merckLogo.img} alt={footerCopy.footerBottom.merckLogo.alt} />
          </s.BottomFooterRight>

        </s.BottomFooterWrapper>
      </s.StyledBottomFooter>

    </s.StyledFooter>

  );
};


export default Footer;
