import styled from 'styled-components';
import * as colors from '../../styles/shared/colors';
import { Link } from 'gatsby';

import { WideWrapper } from '../../styles/shared/layout';
import { respondTo } from '../../styles/shared/breakpoints';


// Footer Top
//----
export const StyledFooter = styled.footer`
  background-color: ${colors.charcoal};
`;

export const FooterWrapper = styled(WideWrapper)`
  padding: 0 20px;
`;

export const FooterUtils = styled.div`
  padding: 10px 0 0;

  ${respondTo.sm`
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding-top: 32px;
  `}
`;

export const FooterUtilsColumn = styled.div`
  &:first-child {
    margin-bottom: 32px;
  }

  ${respondTo.sm`    
    &:first-child {
      margin-bottom: 0;
    }
  `}
`;

export const FooterLogoLink = styled(Link)`
  display: block;
  height: 65px;
  margin-bottom: 20px;

  img {
    display: block;
    height: 100%;
    width: auto;
    filter: brightness(0) invert(1);
  }

  ${respondTo.sm``}
`;

export const FooterLink = styled(Link)`
  display: block;
  padding: 2px 0;
  margin-bottom: 10px;
  font-size: 15px;
  color: ${colors.skyHover};
  text-decoration: none;

  &:first-child {
    margin-top: 0px;
  }

  ${respondTo.sm`
    margin-bottom: 8px;

    &:first-child {
      margin-top: 20px;
    }

    &:hover {
      text-decoration: underline;
    }
  `}
`;

export const ButtonRow = styled.div`
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }

  a {
    &:after {
      background-size: 10px, 10px, 10px, 10px;
    }
  }
`;

export const FooterUtilsSublinks = styled.div`
  padding: 32px 0;
  text-align: center;
  overflow: hidden; // for lines

  @media (max-width: 768px) {
    padding-top: 16px;
  }

  ${respondTo.sm`
    padding: 16px 0 24px;
  `}
`;

export const FooterSublink = styled.a`
  display: inline-block;
  font-size: 12px;
  padding: 3px;
  margin: 0 8px;
  color: ${colors.skyHover};
  text-decoration: none;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  appearance: none;

  @media (max-width: 768px) {
    margin-bottom: 12px;
  }

  ${respondTo.sm`
    &:hover {
      text-decoration: underline;
    }
  `}
`;

export const SublinkBackground = styled.div`
  position: relative;
  display: inline-block;
  max-width: 70%;
  background-color: ${colors.charcoal};

  &:before,
  &:after {
    content: '';
    display: block;
    width: 100%;
    margin: 0 auto;
    position: absolute;
    top: 50%;
    border-top: 1px solid ${colors.grey2};
  }
  &:before {
    right: 100%;
  }
  &:after {
    left: 100%;
  }

  ${respondTo.sm`
    &:before {
      right: calc(100% + 36px);
    }
    &:after {
      left: calc(100% + 36px);
    }

  
  `}

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    text-align: left;
    a {
      margin-left: 0;
    }
    &:before {
      top: -15px;
      left: 0;
      width: 100vw;
    }
    &:after {
      display: none;
    }
  }
`;


// Footer Bottom
//----
export const StyledBottomFooter = styled.div`
  background-color: ${colors.black};
`;

export const BottomFooterWrapper = styled(WideWrapper)`
  padding: 0 20px;

  ${respondTo.sm`
    padding-top: 20px;
    padding-bottom: 29px;
    display: flex;
    align-items: flex-start;
    column-gap: 30px;
  `}
`;

export const BottomFooterLeft = styled.div`
  margin-bottom: 40px;
  padding: 20px 0 5px;
  font-size: 12px;
  line-height: 15px;
  color: ${colors.pebble2};

  

  ${respondTo.sm`
    flex: 1;
    margin: 0;
    padding: 0;
  `}
`;

export const BottomFooterSubcopy = styled.div`
  margin: 10px 0 0;
`;

export const BottomFooterRight = styled.div`
  padding: 5px 0 20px;

  img {
    display: block;
    height: 32px;
    width: auto;

    &.img-merck {
      margin-bottom: 12px;
    }
  }
  
  ${respondTo.sm`
    flex: 1;
    padding: 20px 0 0;
    width: 50%;
    text-align: right;

    img {
      display: inline-block;

      &.img-merck {
        margin-bottom: 0;
        margin-left: 20px;
      }
    }
  `}
`;

export const AccessibilityLink = styled.a`
  display: block;

  img {
    display: block;
    height: 32px;
    width: auto;
    margin-bottom: 30px;
  }

  ${respondTo.sm`
    display: inline-block;

    img {
      margin: 0;
    }
  `}
`;

