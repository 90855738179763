import styled from 'styled-components';

import * as Text from '../../styles/shared/typography';
import * as colors from '../../styles/shared/colors';
import { respondTo } from '../../styles/shared/breakpoints';
import { InformationDrawer } from './Drawer/styles';
import PageSection from '../PageSection';

const isiBodyFontSize = '17px';


export const SafetyPageContainer = styled(PageSection)`
  height: 100%;
  > section {
    margin-bottom: 60px;

    &:last-child {
      margin-bottom: 0;
    }

    ${Text.H3} {
      margin-bottom: 20px;
      text-transform: uppercase;
      
      ${respondTo.sm`
        margin-bottom: 30px;
      `}
    }
  }

`;

export const IsiBody = styled(Text.Body)`
  overflow: auto;
  height: 100%;
  margin-bottom: 24px;
  font-size: ${isiBodyFontSize};

  ${respondTo.sm`
    margin-bottom: 32px;
    gap: 32px;
  `}

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;

  ${InformationDrawer} & {
    padding: 20px 20px 30px;
  
    ${respondTo.md`
      margin-left: 30px;
      margin-right: 30px;
      padding: 30px 0px 50px;
    `}
  }
`;


// Safety List

export const SafetyListContainer = styled.section`
  height: 100%;
  overflow: auto;

  ${InformationDrawer} & {
    margin: 0;
    padding: 20px 20px 30px;

    ${respondTo.md`
      padding: 30px 40px 50px;
    `}
  }
`;

export const SafetyItem = styled.div`
  margin-bottom: 24px;
`;

export const SafetyItemTitle = styled(Text.H6)`
  font-size: ${isiBodyFontSize};
  margin-bottom: 10px;
  color: ${colors.primaryButtonBlue};
`;

export const SafetyItemList = styled(Text.Ul)`
  li {
    font-size: ${isiBodyFontSize};
  }
  &:last-child {
    padding-bottom: 0;
  }
`;

export const SafetyWarningTitle = styled(SafetyItemTitle)`
  color: ${colors.typeDark};
  ${({flat}) => flat && 'margin-bottom: 1px;'}
`;

export const SafetyWarningList = styled(SafetyItemList)`
  li {
    font-size: ${isiBodyFontSize};
    font-weight: 700;
    ${({flat}) => flat && 'margin-bottom: 0px;'}
  }
`;

export const FinalWarning = styled(SafetyItemTitle)`
  margin: 0;
  line-height: calc(1em + 6px);

  a {
    color: ${colors.secondaryBrandRed};
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
`;

export const SafetyJobCode = styled(Text.Footnote)`
  display: block;
  margin-top: 24px;
  text-align: right;
`;