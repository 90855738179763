import { css } from 'styled-components';
import * as colors from '../../../styles/shared/colors';
import { splitGradient } from './BaseBtn';

import downloadArrowWhite from '../../../assets/icon-colors/download-arrow-white.svg';
import downloadBarWhite from '../../../assets/icon-colors/download-bar-white.svg';
import externalArrowWhite from '../../../assets/icon-colors/external-arrow-white.svg';
import externalDotWhite from '../../../assets/icon-colors/external-dot-white.svg';


const SecondaryCss = css`
  color: ${({ theme }) => (theme === 'light' ? colors.white : colors.buttonMain)};
  border: 1px solid transparent;
  border-color: ${({ theme }) => (theme === 'light' ? colors.white : colors.buttonMain)};
  background-image: ${({ theme }) => (theme === 'light')
    ? splitGradient('transparent', colors.white)
    : splitGradient(colors.white, colors.buttonHover)};

  &:hover,
  &:active {
    background-position: right;
    border-color: ${colors.buttonHover};
    color: ${({ theme }) => (theme === 'light' ? colors.buttonMain : colors.white)};
  }

  &[download] {
    &::after {
      background-color: ${colors.buttonMain};
      background-image: url(${downloadBarWhite}), url(${downloadArrowWhite});
    }

    @media (hover: hover) {
      &:hover,
      &:active{
        &::after {
          background-color: ${colors.buttonHover};
        }
      }
    }
  }

  ${({ external }) => external && css`
    &::after {
      background-color: ${colors.buttonMain};
      background-image: url(${externalDotWhite}), url(${externalArrowWhite});
    }

    @media (hover: hover) {
      &:hover,
      &:active {
        &::after {
          background-color: ${colors.buttonHover};
        }
      }
    }
  `}

  &[disabled] {
    background-color: ${colors.white};
    color: ${colors.slate};
    border-color: ${colors.slate};

    &[download]&::after {
      background-color: ${colors.slate};
    }
    ${({ external }) => external && css`
      &::after {
        background-color: ${colors.slate};
      }
    `}
  }
`;


export default SecondaryCss;