import styled, { css } from 'styled-components';

import { BaseButtonLink } from '../../../styles/shared/typography';
import { downloadAnimation, externalAnimation } from '../../../styles/shared/keyframes';
import * as colors from '../../../styles/shared/colors';

import externalArrowWhite from '../../../assets/icon-colors/external-arrow-white.svg';
import externalDotWhite from '../../../assets/icon-colors/external-dot-white.svg';


export const splitGradient = (leftColor, rightColor) =>
  `linear-gradient(to right, ${leftColor} 0%, ${leftColor} 50%, ${rightColor} calc(50% + .1px), ${rightColor} 100%);`;


export const BaseButton = styled(BaseButtonLink)`
  min-width: 120px;
  margin: ${({margin}) => margin ? '0 0 14px' : '0'};
  padding: 12px 25px 11px;
  display: inline-flex;
  justify-content: center;
  align-items: stretch;
  overflow: hidden;
  text-align: center;
  text-decoration: none;

  border-radius: 50px;
  cursor: pointer;
  background-size: 201% 100%;
  background-origin: border-box;
  border: none;
  transition:
    background-position 0.2s ease-in-out,
    color 0.2s ease-in-out;


  &[download] {
    position: relative;
    padding-right: 56px;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 44px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 10px 12px;
    }

    @media (hover:hover) {
      &:hover {
        &::after {
          animation-duration: 0.3s;
          animation-name: ${downloadAnimation};
        }
      }
    }
  }

  &[disabled] {
    background-image: none;
    pointer-events: none;
  }

  ${({ external }) => external && css`
    position: relative;
    padding-right: 56px;

    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 44px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 10px 10px;
    }
    &[disabled] {
      &::after {
        background-image: url(${externalDotWhite}), url(${externalArrowWhite});
        background-color: ${colors.concrete};
      }
    }

    @media (hover:hover) {
      &:hover {
        &::after {
          animation-duration: .4s;
          animation-name: ${externalAnimation};
        }
      }
    }
    `
  }

${({ externalInverted }) => externalInverted && css`
    position: relative;
    padding-right: 56px;

    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 44px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 20px 20px;
    }
    `
  }

  ${({ externalInverted2 }) => externalInverted2 && css`
    position: relative;
    padding-right: 8px;

    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      right: 0px;
      height: 100%;
      width: 44px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 20px 20px;
    }
    `
  }

${({ continual }) => continual && css`
    position: relative;
    padding-right: 46px;

    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 44px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 16px 16px;
    }
    `
  }
  
${({ continualInverted }) => continualInverted && css`
    position: relative;
    padding-right: 46px;

    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 44px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 16px 16px;
    }
    `
  }

${({ bcbContinual }) => bcbContinual && css`
    position: relative;
    padding-right: 74px;

    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      right: 0px;
      height: 100%;
      width: 44px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 20px 20px;
    }
    `
  }


`;