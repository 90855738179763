import React from 'react';
import PropTypes from 'prop-types';
import { setPropsFromURL } from '../../util/cta';
import { StyledButton } from './styles';


const Button = ({to, newWindow = true, children, variant, ...props}) => {
  const linkProps = (to) ? setPropsFromURL(to, newWindow) : {as: 'button'};

  return (
    <StyledButton {...linkProps} variant={variant} {...props}>
      { children }
    </StyledButton >
  );
};


Button.propTypes = {
  to: PropTypes.string,
  newWindow: PropTypes.bool,
  variant: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'primaryBlue']),
};


export default Button;