import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';
import fonts from './_fonts';
import general from './_general';


const GlobalStyles = createGlobalStyle`
  ${reset}
  ${fonts}
  ${general}

  #onetrust-banner-sdk {
    z-index: 998 !important; //hides the OneTrust CCPA banner behind the "Are you a HCP" modal
  }
`;


export default GlobalStyles;