import { charcoal } from '../shared/colors';


const GeneralStyles = `
* {
  box-sizing: border-box;
}
html,
body {

  max-width: 100%;

  &.no-scroll {
    overflow: hidden;
 
 
  }
}
body {
  font-family: 'proxima-nova', sans-serif;
  font-size: 16px;
  color: ${charcoal};
  
}


a,
button {
  font-family: inherit;
}
sup {
  vertical-align: top;
  position: relative;
  top: -0.3em;
  font-size: max(50%, 10px);
}
sub {
  vertical-align: bottom;
  position: relative;
  bottom: -0.3em;
  font-size: max(42%, 10px);
}
.no-wrap {
  white-space: nowrap;
}
em {
  font-style: italic;
}
strong {
  font-weight: bold;
}
`;


export default GeneralStyles;