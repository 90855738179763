import styled from 'styled-components';

import safetyBack from '../../../assets/images/bg-prpl-red-dots.jpg';

import * as Text from '../../../styles/shared/typography';
import * as colors from '../../../styles/shared/colors';
import { respondTo } from '../../../styles/shared/breakpoints';
import { fadeIn } from '../../../styles/shared/keyframes';

const INDICATIONS_AND_SAFETY_CONTAINER = 9;
const INDICATIONS_DRAWER_LAUNCH_BUTTON = 41;
const INDICATIONS_INFORMATION_DRAWER = 100;
const SAFETY_DRAWER_LAUNCH_BUTTON = 43;
const SAFETY_INFORMATION_DRAWER = 44;

const header_bump_out_padding = 6;


export const LaunchButtonContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: ${INDICATIONS_AND_SAFETY_CONTAINER};
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  max-width: 1032px;
  margin: 0 auto;

  pointer-events: none;
  cursor: pointer;
  transform: ${({ isCollapsed }) => (isCollapsed ? 'translateY(100%)' : 'none')};
  transition: transform 0.3s ease-in-out;

  > * {
    pointer-events: auto;
  }

  ${respondTo.md`
    padding: 0 30px;
  `}
`;

export const LaunchButton = styled.div`
  padding: 10px 14px; 
  border: 1px solid ${colors.silver};
  overflow: hidden;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  background-color: ${colors.white};
  border-radius: 8px 8px 0 0;

  ${respondTo.md`
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.16);
    transition: transform 0.3s ease-in-out;

    &:hover {
      box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.25);
    }
  `}

  header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

  }

  &:hover header::after {
    background-color: ${colors.mist};
  }

  > div {
    overflow: hidden;
  }
`;

export const IndicationsLaunchButton = styled(LaunchButton)`
  z-index: ${INDICATIONS_DRAWER_LAUNCH_BUTTON};
  height: 85px;
  margin-bottom: -10px;
  transition: height 0.3s ease-in-out, padding 0.3s ease-in-out;

  button {
      background-color: transparent;
      border: none;
      cursor: pointer;
  }

  ${respondTo.sm`
    height: 105px;
  `}
`;

export const SafetyLaunchButton = styled(LaunchButton)`
  z-index: ${SAFETY_DRAWER_LAUNCH_BUTTON};
  height: 96px;
  margin-bottom: -5px;

  button {
      background-color: transparent;
      border: none;
      cursor: pointer;
  }

  ${respondTo.md`
    margin-bottom: 0px;
  `}
`;

export const PreviewTitle = styled.span`
  font-size: 18px;
  line-height: 21px;
  font-weight: 600;
  padding-bottom: 3px; 
  color: ${colors.weliregBlue};
`;

export const PreviewText = styled(Text.Body)`
  line-height: 20px;
  color: ${colors.charcoal};

  strong {
    color: ${colors.weliregBlue};
    font-weight: 500;
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  top: -5px;
  right: 0px;
  width: 36px;
  height: 36px;
  cursor: pointer;

  padding: 0;
  margin: 0;
  border: none;
  background-color: transparent;
  transition: 0.3s background-color;

  color: ${colors.white};
  font-size: 10px;
  font-weight: 700;
  padding-top: 28px;

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    display: inline-block;
    height: 18px;
    width: 3px;
    border-radius: 10px;
    background-color: ${colors.white};
  }
  &:before {
    transform: translate(-50%, -50%) rotate(45deg);
  }
  &:after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  &:hover {
    
  }

  ${respondTo.sm`
    top: -5px;
    right: 0px;
    width: 44px;
    height: 44px;
    padding-top: 30px;
  `}
`;

export const DrawerHeader = styled.div`
  position: relative;
  padding: 30px; // 20px 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  
  border-radius: 10px 10px 0px 0px;
  background: linear-gradient(90deg, #2E3790 0.01%, #3F89C8 100%);

  ${respondTo.sm`
    padding: 30px;
  `}

`;

export const DrawerHeaderText = styled(Text.H2)`
  color: ${colors.white};
  margin-bottom: 0;
`;

export const ModalButtonWrapper = styled.div`
    position: relative;
    height: 36px;
    width: 40px;
`;

export const DrawerSubheaderText = styled(Text.Subtitle)`
  display: inline-block;
  margin: 16px 0 0;
  padding: ${header_bump_out_padding}px 8px;
  border-radius: 4px;
  font-weight: 500;
  color: ${colors.white};
  background-color: ${colors.weliregBlue};
`;

export const InformationDrawer = styled.div`
  display: none;
  position: fixed;
  z-index: ${({ drawerName }) =>
    drawerName === 'indications' ? INDICATIONS_INFORMATION_DRAWER : SAFETY_INFORMATION_DRAWER};
  top: 100vh;
  left: 0;
  right: 0;
  flex-direction: column;
  height: 100%;
  max-width: 1032px;
  overflow: hidden;
  margin: 0 auto;
  cursor: auto;
  transition: 0.4s transform ease-in-out;

  > .container {
    background-color: ${colors.white};
    overflow: hidden;
    height: 100%;
  }

  &.information-drawer-enter {
    transform: translateY(0);
    opacity: 1;
    display: flex;
  }

  &.information-drawer-enter-active,
  &.information-drawer-enter-done {
    transform: translateY(-100vh);
    opacity: 1;
    display: flex;
  }

  &.information-drawer-exit {
    transform: translateY(-100vh);
    display: flex;
  }

  &.information-drawer-exit-active,
  &.information-drawer-exit-done {
    transform: translateY(0);
    display: flex;
  }

  &.information-drawer-exit-done {
    opacity: 0;
    display: none;
  }

  ${respondTo.md`
    padding: 0 20px;
  `}
`;

export const DrawerOverlay = styled.div`
  position: fixed;
  z-index: ${INDICATIONS_AND_SAFETY_CONTAINER - 1};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  background-color: rgba(50,50,50, 0.7);
  display: ${({ isActive }) => (isActive ? 'block' : 'none')};
  animation: ${fadeIn} 0.5s;
  cursor: pointer;
`;
