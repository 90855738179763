import styled from 'styled-components';
import * as colors from '../../styles/shared/colors';
import { Body, H3 } from '../../styles/shared/typography';
import { respondTo } from '../../styles/shared/breakpoints';
import imgLogo from '../../assets/images/logo-welireg.svg';
import arrowWhite from '../../assets/icon-colors/arrow-right-white2.svg';
import externalArrowWhiteWithDots from '../../assets/icon-colors/external-arrow-white-with-dots.svg';
import Button from '../Button';

export const ModalBgContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.7);
  z-index: 999;
`;

export const ModalWindow = styled.div`
  background: ${colors.white};
  position: fixed;
  top: 50%;
  left: 50%;
  width: 93%;
  max-width: 800px;
  height: auto;
  max-height: 95vh;
  border-radius: 8px;
  overflow: auto;
  transform: translate(-50%,-50%);

  ${respondTo.modalMd`
    display: flex;
    overflow: hidden;
  `}
`;

export const ModalLogoBox = styled.div`
  width: 100%;
  height: 145px;
  background-image: url("${imgLogo}");
  background-size: auto 60%;
  background-position: center 45%;
  background-repeat: no-repeat;
  border-bottom: 1px solid ${colors.pebble};

  ${respondTo.modalMd`
    display: inline-block;
    flex: 0 0 auto;
    width: 40%;
    max-width: 300px;
    height: auto;
    background-size: 80% auto;
    border-bottom: 0;
    border-right: 1px solid ${colors.pebble};
  `}
`;

export const ModalContent = styled.div`
  padding: 20px 15px;
  text-align: center;
  .modal-button {
    font-weight: 700;
    border-radius: 10px;
  }

  button,
  a {
    width: auto;
    vertical-align: bottom;
    margin-bottom: 0;

  }

  /* // Reset external secondary link styling */
  a {
    padding-right: 25px;

    &:after {
      display: none;
    }
  }



  ${respondTo.modalSm`
    button,
    a {
      width: auto;
      margin-right: 20px;
      margin-bottom: 0;
    }
  `}

  ${respondTo.modalMd`
    display: inline-block;
    flex: 1;
    padding: 40px;
    padding-top: 90px;
    padding-bottom: 90px;

    button,
    a {
      width: 100%;
      margin-right: 0;
      margin-bottom: 20px;
    }
  `}

  ${respondTo.sm`
    button {
      width: auto;
      margin-right: 84px;
      margin-bottom: 34px;
    }
    a {
      width: auto;
      margin-right: 0px;
      margin-bottom: 34px;
    }
  `}
`;

export const ModalHeader = styled(H3)`
  color: ${colors.grey};
  margin-bottom: 19px;
  font-weight: 400;
  font-size: 24px;
  ${respondTo.sm`
    font-size: 30px;
  `}
`;

export const ModalBody = styled(Body)`
  margin: 19px 0 0;

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: ${colors.charcoal2};

  ${respondTo.modalMd`
    margin: 19px 0 0;
  `}
`;

export const ModalDivider = styled.div`
  border-top: 2px solid #BEBEBE;
  border-left: 0;
  margin: 20px 0;
  width: 100%;

  ${respondTo.sm`
    height: 100px;
    border-top: 0;
    border-left: 1px solid #BEBEBE;
    display: inline-flex;
    margin: 0 84px 0 0;
    width: auto;
  `}
`;

export const ArrowWhiteBtn = styled.div`
  background-image: url(${arrowWhite});
  display: inline-block;
  background-repeat: no-repeat;
  height: 16px;
  width: 16px;
  margin-left: 12px;
  margin-top: 3px;
`;

export const ArrowExternalBtn = styled.div`
  background-image: url(${externalArrowWhiteWithDots});
  display: inline-block;
  background-repeat: no-repeat;
  height: 16px;
  width: 16px;
  margin-left: 12px;
  transform: translateY(2px);
`;

export const LightBlueButton = styled(Button)`
  background: ${colors.primaryButtonBlueLight};
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  padding: 12px 16px 12px 16px;

  :hover {
    background: ${colors.primaryButtonBlue};
  }
`;