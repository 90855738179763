// PLEASE USE ONLY THE FOLLOWING DEFINED COLORS UNTIL THE "---END---"

export const brandBlueDark = '#2E3790';
export const brandBlueLight = '#3F89C8';
export const brandOrange = '#F7941F';
export const brandRed = '#D73126';
export const statGrey = '#808285';
export const statBlueLightTint = '#9FC4E3';
export const statBlack = '#00000e'
export const statGreyTint = '#BFC1C2';
export const ink2 = '#262626';
export const charcoal2 = '#444444';
export const slate2 = '#696969';
export const concrete2 = '#8C8C8C';
export const pebble2 = '#BEBEBE';
export const silver2 = '#DDDDDD';
export const mist2 = '#EBEAEB';
export const cloud = '#F3F3F3';
export const white = '#FFF';
export const error = '#DB3933';
export const warning = '#FFE105';
export const focus = '#006EAD';
export const bodyCopy = 'rgba(0, 0, 0, 0.90)';
export const campaignBlue = '#3E88C8';
export const campaignSoftBlue = '#7DA3D0';
export const purple = '#7774B0';
export const darkPurple = '#2E3790';


// other colors used but not in the library: 
export const primaryButtonBlue = '#343F99';
export const tintsBlue = '#E2EDF7';
export const secondaryBrandRed = '#D63126';
export const logoKo = '#EEE';
export const primaryButtonBlueLight = '#3E8AC7';
export const closeGreen = '#2C6D3C';
export const neutralBodyGray = '#1A1A1A';
export const tintsBlueLt2 = '#F1F4FA';
export const grayImageAnno = '#58595B';

// ---------END---------









// OLD SITE COLORS BELOW
// commented out colors match new variable names. I left it there for now just for reference

// Primary
export const weliregBlue =  '#2E378F';
export const welBlueLight = '#E5E6F1';
export const welBlueLightest = '#F4F5F9';
export const blueLight =    '#C0D1EB';
export const blueLighter =  '#D3DEF1';
export const cobalt =       '#3072AB';
export const cobaltLight =  '#D7E0E8';
export const cobaltLighter = '#EFF4F8';
export const cobaltBorder = '#98BFDF';
export const plum =         '#9C3D55';
export const grape =        '#62417A';
export const ruby =         '#D63227';
export const rubyDark =     '#B52A21';
export const purpleDark =   '#5D4377';
export const gold =         '#fec111';

// Secondary
export const merckTeal =  '#009999';
export const morning =    '#6DCEB2';
export const afternoon =  '#86DA64';
export const evening =    '#28227E';
export const midnight =   '#0C2340';

// CTAs
export const sky =            '#70BDFF';
export const skyHover =       '#A8DFFF';
export const blueberry =      '#006EAD';
export const blueberryHover = '#004A85';

// Form States
// export const error =    '#DB3933';
// export const warning =  '#FFE105';

// Unbranded
export const marineGreen =        '#2D7489';
export const kelp =               '#69ADA8';
export const unbrandedYellow =    '#FCF9D0';
export const unbrandedYellowDark = '#F6E923';

// Neutrals (dark to light)
export const black =    '#000000';
export const ink =      '#070708';
export const vhlGrey =  '#231F20';
export const charcoal = '#3C434C';
export const grey =     '#444444';
export const grey2 =     '#979797';
export const greylight = '#EBEAEB';
export const slate =    '#5A6470';
export const concrete = '#798694';
export const pebble =   '#A5AFB6';
// export const pebble2 =  '#bebebe';
export const silver =   '#C5CACD';
export const mist =     '#DBE1E6';
// export const cloud =    '#F3F3F3';
// export const white =    '#FFFFFF';

// Uses
export const typeDark = charcoal;
export const typeLight = white;

export const buttonMain = ruby;
export const buttonHover = purpleDark;
