import React from 'react';
import { Link } from 'gatsby';


const HeaderLink = (props) => (
  <Link activeClassName="current" {...props} />
);


export default HeaderLink;
