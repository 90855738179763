import React from 'react';

import * as s from './styles';
import safetyCopy from '../../copy/safety-panel/safety';


const SafetyList = ({ showSsiJobCode }) => {
  return (
    <s.SafetyListContainer>

      <s.SafetyItem>
        <s.SafetyWarningTitle>
          { safetyCopy.warningList.title }
        </s.SafetyWarningTitle>

        <s.SafetyWarningList>

          {safetyCopy.warningList.items.map((item, i) => (
            <li
              key={i}
              dangerouslySetInnerHTML={{__html: item}}
            />
          ))}

        </s.SafetyWarningList>
      </s.SafetyItem>

      {safetyCopy.sections.map((sectionObj, i) => (
        <s.SafetyItem key={i}>
          <s.SafetyItemTitle>
            { sectionObj.title }
          </s.SafetyItemTitle>

          <s.SafetyItemList>

            {sectionObj.items.map((item, i) => (
              <li
                key={i}
                dangerouslySetInnerHTML={{__html: item}}
              />
            ))}

          </s.SafetyItemList>
        </s.SafetyItem>
      ))}

      <s.FinalWarning
        dangerouslySetInnerHTML={{__html: safetyCopy.finalWarning}}
      />

      {showSsiJobCode &&
        <s.SafetyJobCode>
          { safetyCopy.safetyJobCode }
        </s.SafetyJobCode>
      }

    </s.SafetyListContainer>
  );
};


export default SafetyList;
