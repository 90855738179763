import React from 'react';

import { H3 } from '../../styles/shared/typography';
import * as s from './styles';
import SafetyList from './SafetyList';

import IndicationCopy from '../../copy/safety-panel/indication';
import SafetyCopy from '../../copy/safety-panel/safety';

function IndicationAndSafetyPage() {
  return (
    <s.SafetyPageContainer narrow btmFlat>
      <section>
        <H3>{ IndicationCopy.title }</H3>
        <s.IsiBody>
          <p>{ IndicationCopy.body }</p>
          <p>{ IndicationCopy.body2 }</p>
        </s.IsiBody>
      </section>

      <section>
        <H3>{ SafetyCopy.title }</H3>
        <div id="safety-scroll-target">
          <SafetyList />
        </div>
      </section>
    </s.SafetyPageContainer>
  );
}


export default IndicationAndSafetyPage;
