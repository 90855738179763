import LogoImg from '../assets/images/logo-welireg.svg';
import React from 'react';

const headerCopy = {
  logoImg: LogoImg,
  logoAlt: 'WELIREG® (belzutifan) Logo',

  vhlLogoText: 'Home',
  hamburgerLabels: {
    whileOpen: 'Close',
    whileClosed: 'Menu',
  },
  utilLinks: {
    left: [
      {
        copy: 'Prescribing Information',
        href: 'https://www.merck.com/product/usa/pi_circulars/w/welireg/welireg_pi.pdf',
        title: 'Opens a new window to view the Prescribing Information resource',
        keys: ['vhl', 'rcc', 'mt', 'ut']
      },
      {
        copy: 'Medication Guide',
        href: 'https://www.merck.com/product/usa/pi_circulars/w/welireg/welireg_mg.pdf',
        title: 'Opens a new window to view the Medication Guide resource',
        keys: ['vhl', 'rcc', 'mt', 'ut']
      },
    ],
    right: [
      {
        copy: 'Ask Merck',
        href: 'https://www.askmerckoncology.com',
        title: 'Opens a new window to Ask Merck website',
        keys: ['vhl', 'rcc', 'mt', 'ut']
      },
      {
        copy: 'Medical Information',
        href: 'https://www.merckmedicalportal.com/s/medical-information?AN=P5mzpu8oRgks2pD&HCP=m7pcsRtrbIqUITdRC=d0hMzkhIrEzIQPb',
        title: 'Opens a new window to Merck medical portal',
        keys: ['vhl', 'rcc', 'mt', 'ut'],
        showModal: true
      },
      {
        copy: 'Visit Patient Site',
        href: 'https://www.welireg.com/',
        title: 'Opens a new window to WELIREG patient website',
        keys: ['vhl', 'rcc', 'mt', 'ut']
      },
    ],
  },
  navLinks: [
    {
      type: 'dropdown',
      label: 'Select Indication',
      options: [
        {
          label: 'Advanced RCC',
          href: '/advanced-renal-cell-carcinoma/'
        },        
        {
          label: 'VHL Disease',
          href: '/von-hippel-lindau/'
        },
      ],
      keys: ['mt', 'vhl', 'rcc', 'ut']
    },
    {
      label: 'What Is VHL Disease?',
      href: '/von-hippel-lindau/what-is-von-hippel-lindau',
      keys: ['vhl']
    },
    {
      label: 'Mechanism of Action',
      href: '/von-hippel-lindau/mechanism-of-action',
      keys: ['vhl']
    },
    {
      label: 'Efficacy',
      href: '/von-hippel-lindau/efficacy',
      keys: ['vhl']
    },
    {
      label: 'Safety',
      href: '/von-hippel-lindau/adverse-reaction-profile',
      keys: ['vhl']
    },
    {
      label: 'Guidelines',
      href: '/von-hippel-lindau/guidelines',
      keys: ['vhl']
    },
    {
      label: 'Dosing',
      href: '/von-hippel-lindau/dosing',
      keys: ['vhl']
    },
    {
      label: <>Resources &&nbsp;Dispensing</>,
      href: '/von-hippel-lindau/resources',
      keys: ['vhl']
    },

    {
      label: 'Mechanism of Action',
      href: '/advanced-renal-cell-carcinoma/mechanism-of-action',
      keys: ['rcc']
    },
    {
      label: 'Efficacy',
      href: '/advanced-renal-cell-carcinoma/efficacy',
      keys: ['rcc']
    },
    {
      label: 'Treatment Sequencing',
      href: '/advanced-renal-cell-carcinoma/treatment-considerations',
      keys: ['rcc']
    },
    {
      label: 'Safety',
      href: '/advanced-renal-cell-carcinoma/safety',
      keys: ['rcc']
    },
    {
      label: 'Dosing',
      href: '/advanced-renal-cell-carcinoma/dosing',
      keys: ['rcc']
    },
    {
      label: <>Resources &&nbsp;Dispensing</>,
      href: '/advanced-renal-cell-carcinoma/resources-and-access',
      keys: ['rcc']
    },


  ],
};

export default headerCopy;
