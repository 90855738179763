import styled, { css } from 'styled-components';
import { Link } from 'gatsby';

import { WideWrapper } from '../../styles/shared/layout';
import * as colors from '../../styles/shared/colors';
import { respondTo } from '../../styles/shared/breakpoints';
import { fadeIn } from '../../styles/shared/keyframes';

import menuBlueberry from '../../assets/icon-colors/menu-blue.svg';
import menuBlueberryRounded from '../../assets/icon-colors/menu-blue-rounded.svg';
import closeBlueberry from '../../assets/icon-colors/close-blue.svg';
import closeCharcoal from '../../assets/icon-colors/close-charcoal.svg';
import Button from '../Button';
import externalArrowWhiteWithDots from '../../assets/icon-colors/external-arrow-white-with-dots.svg';

const nonBrandModifier = css`
  .non-brand-logo {
    position: absolute;
    top: 50%;
    left: 20px;
    font-weight: 700;
    transform: translateY(-50%);
    opacity: 0;
    transition: 0.3s opacity;
    color: ${colors.typeDark};

    ${ respondTo.md`
      left: 50%;
      transform: translate(-50%, -63%);
    `}
  }

  &.non-brand {
    transition: 0.3s background-color;

    .main-logo {
      opacity: 0;
      transition: 0.3s opacity;
    }
    .non-brand-logo {
      opacity: 1;
      transition:
        0.3s 0.3s opacity,
        0.3s color;
    }

    &:hover {
      background-color: ${colors.cloud};

      .non-brand-logo {
        color: ${colors.weliregBlue};
      }
    }
  }
`;


export const StyledHeader = styled.header`
  /* position: sticky;
  top: 0; */
  width: 100%;
  z-index: 20;
  border-bottom: 1px solid ${colors.silver};

  &.open {
    z-index: 50;
  }

`;

export const TopNav = styled.div`
  position: relative;
  z-index: 2;
  background-color: ${colors.charcoal};
`;

export const TopNavWrapper = styled(WideWrapper)`
  ${ respondTo.md`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
  `}
`;

export const TopNavLinkGroup = styled.div`
  display: ${({desktopOnly}) => desktopOnly ? 'none' : 'flex'};
  padding: 9px 0;

  gap: 30px;
  margin-left: 10px;
  margin-right: 10px;

  > a {
    padding: 0;
    font-size: 12px;
    font-weight: 400;
  }

  ${ respondTo.md`
    display: inline-block;
    flex: 0 0 auto;
    width: auto;
  `}
`;

export const TopNavLinkGroupRight = styled(TopNavLinkGroup)`
  ${ respondTo.md`
    > a {
      margin-left: 20px;

      &:first-child {
        margin: 0;
      }
    }
  `}
  a {
    &:after {
      background-size: 10px, 10px, 10px, 10px;
    }
  }
`;

export const TopNavLink = styled.a`
  display: inline-block;
  flex: 1 0 auto;
  padding: 2px 0;
  text-align: center;
  font-size: 0.75rem;
  color: ${colors.white};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  ${ respondTo.md`
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
  `}

 
`;

export const HeaderBar = styled.div`
  position: relative;
  background-color: ${colors.white};
`;

export const HeaderBarWrapper = styled(WideWrapper)`
  display: flex;
  justify-content: space-between;

  ${ respondTo.md`
    padding-left: 20px;
    padding-right: 20px;
  `}
`;

export const LogoLink = styled(Link)`
  position: relative;
  display: inline-block;
  flex: 0 0 auto;
  padding: 16px;

  img {
    display: block;
    height: 67px;
    width: auto;
    transition: 0.3s 0.3s opacity;
  }
  ${nonBrandModifier};

  ${ respondTo.md`
    margin-right: 20px;
    padding: 16px 0;

    img {
      height: 67px;
    }
  `}
`;

export const HamburgerButton = styled.button`
  position: relative;
  z-index: 2;
  display: inline-block;
  flex: 0 0 auto;
  padding: 35px 15px 6px;
  border: 0;
  border-radius: 0;
  background-color: ${colors.white};

  background-image: url(${menuBlueberryRounded});
  background-repeat: no-repeat;
  background-position: center top 26px;
  cursor: pointer;


  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;


  color: ${colors.charcoal};
  text-transform: uppercase;
  text-align: center;
  white-space: nowrap;

  &:hover {

  }
  &.open {
    background-image: url(${closeCharcoal});
    background-size: 14px 14px;
    background-color: transparent;
    background-position: center top 17px;
    padding-top: 30px;
    height: 56px;
    border-left: 2px solid ${colors.silver};
    z-index: 10;
  }

  ${ respondTo.md`
    display: none;
  `}
`;


export const NavTray = styled.div`
  position: absolute;
  top: 0;
  left: 100%;
  width: 280px;
  max-height: 100vh;
  height: 100vh;
  /* overflow: auto; */
  padding-top: 2px;
  background-color: ${colors.white};
  transition: transform 0.4s ease-in-out; // TODO: Better curve
  display: none;

  &.open {
    transform: translateX(-100%);
    display: block;
    z-index: 10;
  }

  ${ respondTo.md`
    display: block;
    flex: 1 0 0;
    position: static;
    z-index: auto;
    left: 0;
    width: auto;
    max-height: none;
    height: auto;
    padding: 0;
    box-shadow: none;
    transition: none;

    &.open {
      transform: 0;
    }
  `}
`;

export const MobileLogoLink = styled(Link)`
  position: relative;
  display: inline-block;

  width: 100%;
  border-bottom: 2px solid ${colors.pebble2};
  padding-top: 2px;
  padding-left: 20px;
  padding-bottom: 6px;

  img {
    display: block;
    height: 46px;
    width: auto;
  }

  ${nonBrandModifier};

  ${ respondTo.md`
    display: none;
  `}
`;

export const PrimaryNav = styled.nav`
  padding: 20px 0 5px 0;
  border-bottom: 1px solid ${colors.pebble2};

  a {
    display: block;
    padding: 12px 20px;
    text-decoration: none;
    font-weight: 500;
    color: ${colors.charcoal};
  }

  ${ respondTo.md`
    display: flex;
    height: 100%;
    align-items: stretch;
    padding: 0;
    border: 0;
    margin-left: 280px;

    a {
      flex: 1 0 0;
      display: inline-flex;
      align-items: flex-start;
      justify-content: center;
      padding: 22px 6px 20px;
      text-align: center;
      font-size: .94rem;
      color: ${colors.charcoal};
      font-weight: 600;
      transition:
        color 0.3s,
        background-color 0.4s;

      &.nav-vhl {
        color: ${colors.slate};
      }

      &.current {
        border-bottom: 4px solid ${colors.secondaryBrandRed};
      }
      
      border-bottom: solid 4px transparent;
      &:hover {
        border-bottom: solid 4px ${colors.secondaryBrandRed};
      }
    }
  `}
`;

export const MobileUtilLinks = styled.div`
  padding: 20px;
  padding-top: 16px;

  > a,
  > button {
    display: flex;
    font-weight: 400;
    width: fit-content;
    padding-right: 40px;
    font-size: 18px;
    line-height: 22px;
  }

  ${ respondTo.md`
    display: none;
  `}
`;

export const MobileNavOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  background-color: rgba(50,50,50, 0.7);
  display: ${({ isActive }) => (isActive ? 'block' : 'none')};
  animation: ${fadeIn} 0.5s;
  z-index: 10;

  ${respondTo.md`
    display: none;
  `}
`;

export const ModalPopUp = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
`;

export const ModalOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
`;

export const ModalContent = styled.div`
  background-color: ${colors.white};
  border-radius: 8px;
  padding: 60px 80px;
  padding-bottom: 35px;
  text-align: center;
  position: relative;

  @media (max-width: 768px) {
    margin-left: 12px;
    margin-right: 12px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .modal-button {
    font-weight: 700;
    border-radius: 4px;
  }

  button:nth-last-child(1) {
    color: ${colors.white};
    margin: 25px 25px 0;
    margin-top: 10px;

  }
  p {
    margin-bottom: 20px;
  }
`;

export const ModalClose = styled.button`
  position: absolute;
  right: 10px;
  top: 5px;
  height: 40px;
  padding-top: 32px;

  border: none;
  background-color: transparent;

  color: ${colors.brandBlueDark};
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  &:before, &:after {
    position: absolute;
    top: 50%;
    left: 50%;
    content: ' ';
    height: 22px;
    width: 3px;
    background-color: ${colors.brandBlueDark};
  }
  &:before {
    transform: translate(-50%, -50%) rotate(45deg);
  }
  &:after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
`;

export const LightBlueButton = styled(Button)`
  background: ${colors.primaryButtonBlueLight};
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  padding: 12px 16px 12px 16px;

  :hover {
    background: ${colors.primaryButtonBlue};
  }
`;

export const ArrowWhiteBtn = styled.div`
  background-image: url(${externalArrowWhiteWithDots});
  display: inline-block;
  background-repeat: no-repeat;
  height: 16px;
  width: 16px;
  margin-left: 12px;
  margin-top: 3px;
`;