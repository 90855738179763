import styled, { css } from 'styled-components';
import * as colors from './colors';
import { respondTo } from './breakpoints';

// 100: thin
// 300: light
// 400: regular
// 500: medium
// 600: semibold
// 700: bold
// 800: extrabold
// 900: black


// Type Utility Imports
//----

export const getLinkStyles = css`
  a {
    color: ${({ theme }) => (theme === 'light' ? colors.sky : colors.ruby)};
    transition: color 0.3s ease-in-out;
  }

  a:hover,
  a:active,
  a:focus {
    color: ${({ theme }) => (theme === 'light') ? colors.skyHover : colors.rubyDark};
    cursor: pointer;
    text-decoration: none;
  }
`;

export const getBoldStyles = css`
  b,
  strong {
    font-weight: 600;
    color: ${({ theme }) => (theme === 'light') ? colors.white : colors.ink};
  }
`;

export const getBodySuperscriptStyles = css`
  sup {
    font-size: 10px;
    vertical-align: top;
    position: relative;
    top: 0.9em;
  }
`;

export const getSmalltextSuperscriptStyles = css`
  sup {
    font-size: 8px;
    vertical-align: top;
    position: relative;
    top: 0.9em;
  }
`;

export const getDataSuperscriptStyles = css`
  sup {
    font-size: max(42%, 10px);
    vertical-align: top;
    position: relative;
    top: 0.2em;
  }
`;



// Headings
//----

const baseHeadings = ({fontSize, largeFontSize, lineHeight, fontWeight, marginBottom, desktopMarginBottom}) => css`
  font-size: ${fontSize};
  line-height: ${lineHeight || 'calc(1em + 4px)'};
  font-weight: ${fontWeight || '700'};
  color: ${({ unbranded, cobalt }) =>
    (unbranded) ? colors.vhlGrey :
    (cobalt) ? colors.cobalt :
    colors.weliregBlue
  };
  margin-bottom: ${marginBottom};

  ${largeFontSize && respondTo.sm`
    font-size: ${largeFontSize};
    margin-bottom: ${desktopMarginBottom};
  `}
`;


export const H1 = styled.h1`
  ${baseHeadings({
    fontSize: '28px',
    largeFontSize: '48px',
  })}
`;

export const H2 = styled.h2`
  ${baseHeadings({
    fontSize: '24px',
    largeFontSize: '32px',
    marginBottom: '16px',
    desktopMarginBottom: '30px',
  })}

  margin-bottom: ${({noMarginBottom}) => noMarginBottom && '0 !important'};
`;

export const H3 = styled.h3`
  ${baseHeadings({
    fontSize: '22px',
    largeFontSize: '28px',
    lineHeight: '1.28em',
  })}
  margin-bottom: 16px;
`;

export const H4 = styled.h4`
  ${baseHeadings({
    fontSize: '20px',
    largeFontSize: '24px',
    lineHeight: '1.25em',
  })}
  ${({thin}) => thin ?
    css`
      font-weight: 400;
      color: ${colors.charcoal};
    `
    : ''
  }
`;

export const H5 = styled.h5`
  ${baseHeadings({
    fontSize: '20px',
  })}
`;

export const H6 = styled.h6`
  ${baseHeadings({
    fontSize: '18px',
  })}
  margin-bottom: 12px;
`;



// Body
//----

const baseCopy = ({ fontSize, lineHeight, fontWeight, marginBottom }) => css`
  margin-bottom: ${marginBottom || '1em'};
  font-size: ${fontSize};
  line-height: ${lineHeight || 'calc(1em + 4px)'};
  font-weight: ${fontWeight || '400'};
  color: ${({ unbranded, color }) =>
    (unbranded) ? colors.vhlGrey :
    (color) ? color :
    colors.typeDark
  };
`;


export const bodyCss = css`
  ${baseCopy({
    fontSize: '16px',
    lineHeight: '1.5em',
    marginBottom: '1em',
  })}

  ${getLinkStyles}
  ${getBoldStyles}

  em {
    font-style: italic;
  }
`;

export const Body = styled.div`
  ${bodyCss}
`;

export const BodySection = styled.div`
  p {
    ${bodyCss}

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const BodyThin = styled(Body)`
  font-weight: 300;
`;

export const BodyLarge = styled.p`
  margin: 0;
  font-size: 24px;
  line-height: 1.3;
  
  ${respondTo.sm`
    font-size: 28px;
    line-height: 1.2;
  `}
`;

export const Eyebrow = styled.span`
  ${baseCopy({
    fontSize: '12px',
  })}
  letter-spacing: .5px;
  text-transform: uppercase;
`;

export const Subtitle = styled.span`
  ${baseCopy({
    fontSize: '20px',
    fontWeight: '300',
  })}
`;

export const Caption = styled.span`
  ${baseCopy({
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: '400',
  })}
`;

export const CaptionLight = styled(Caption)`
  font-weight: 300;
`;

export const Footnote = styled.span`
  ${baseCopy({
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: '300',
  })}
  color: ${colors.black};

  ${getBoldStyles}
`;


export const FootnoteLight = styled(Footnote)`
  font-weight: 300;
`;

export const ButtonText = styled.span`
  ${baseCopy({
    fontSize: '1rem',
    lineHeight: '17px',
    fontWeight: '500',
  })}
`;
export const BaseButtonLink = styled.a`
    ${baseCopy({
    fontSize: '1rem',
    lineHeight: '1.1em',
    fontWeight: '500',
  })}
`;

export const ListTitle = styled(Body)`
  font-weight: 700;
  margin-bottom: 4px;
  color: ${(props) => props.color || colors.cobalt};
`;

export const Reference = styled.span`
  font-size: 14px;
  line-height: 1.1em;
  color: ${colors.black};
`;


// Lists
//----

export const baseListCss = css`
  margin-bottom: 16px;
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  list-style: none;
  padding: 0;
  color: ${({ theme }) => (theme === 'dark' ? colors.white : colors.charcoal)};

  &:last-child {
    margin-bottom: 0;
  }
`;

export const orderedListCss = css`
  li {
    counter-increment: olCounter;
    padding-left: 1.8em;
    margin: 10px 0;
    color: ${({ theme }) => {
    return theme === 'light' ? colors.white : colors.charcoal;
  }};

    &::before {
      content: counter(olCounter) '.';
      display: inline-block;
      width: 1em;
      margin: 0 0.8em 0 -1.8em;
      color: ${colors.weliregBlue}
    }

    ol {
      li {
        counter-increment: olSubCounter;

        &::before {
          content: counter(olSubCounter, upper-alpha) '.';
        }
      }
    }
  }
`;

export const unorderedListCss = css`
  li {
    position: relative;
    padding-left: 16px;
    margin: 0 0 8px;
    color: ${({ theme }) => {
    return theme === 'light' ? colors.white : colors.charcoal;
  }};

    &:last-child {
      margin-bottom: 0;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0.5em;
      left: 0;
      display: inline-block;
      width: 5px;
      height: 5px;
      border-radius: 3px;
      background-color: ${({ unbranded }) => unbranded ? colors.charcoal : colors.ruby};
    }

    ul {
      margin-top: 6px;
    }

    ul li {
      margin-bottom: 8px;
    }

    ul li::before {
      top: 0.6em;
      width: 8px;
      height: 2px;
      border-radius: 0;
    }

    ul ul li::before {
      background-color: transparent;
      border: 1px solid ${({ unbranded }) => unbranded ? colors.charcoal : colors.weliregBlue};
    }
  }
`;

export const Ol = styled.ol`
  ${baseListCss}
  ${orderedListCss}
`;

export const Ul = styled.ul`
  ${baseListCss}
  ${unorderedListCss}
`;

export const showBullet = styled.ul`
  li {
    list-style-type: cirlce !important;
    list-style-position: outside;
  }
`;

export const bulletindent = styled.ul`
  span {
    padding-left: 10px;
  }
`;