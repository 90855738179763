import { css } from 'styled-components';
import * as colors from '../../../styles/shared/colors';
import { tertArrowRightAnimation, externalAnimation, tertDownloadAnimation } from '../../../styles/shared/keyframes';
import { splitGradient } from './BaseBtn';

import arrowRightRuby from '../../../assets/icon-colors/arrow-right-ruby.svg';
import arrowRightBlueberry from '../../../assets/icon-colors/arrow-right-blue.svg';
import arrowRightWhite from '../../../assets/icon-colors/arrow-right-white.svg';
import downloadArrowBlueberry from '../../../assets/icon-colors/download-arrow-blue.svg';
import downloadArrowWhite from '../../../assets/icon-colors/download-arrow-white.svg';
import downloadBarBlueberry from '../../../assets/icon-colors/download-bar-blue.svg';
import downloadBarWhite from '../../../assets/icon-colors/download-bar-white.svg';
import externalArrowBlueberry from '../../../assets/icon-colors/external-arrow-blue.svg';
import externalDotBlueberry from '../../../assets/icon-colors/external-dot-blue.svg';
import circleArrow from '../../../assets/icon-colors/Circle_arrow.svg';



const TertiaryCss = css`
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 4px 0;
  text-decoration: none;
  text-align: left;
  background: none;
  border-radius: 0;
  overflow: visible;
  color: ${({ theme }) => (theme === 'light' ? colors.white : colors.buttonMain)};

  &::after {
    content: '';
    flex: 0 0 20px;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-left: 6px;
    background-image: ${({ theme }) => (theme === 'light')
      ? `url(${arrowRightBlueberry}), ${splitGradient(colors.white, colors.white)}`
      : `url(${arrowRightRuby}), ${splitGradient(colors.white, colors.buttonHover)}`};
    border: ${({ theme }) => (theme === 'light') ? 'none' : `1px solid ${colors.ruby}`};
    background-repeat: no-repeat;
    background-position: center, left;
    background-size: 10px 10px, 200% 100%;
    border-radius: 50%;
    fill: ${colors.ruby};
  }

  @media (hover: hover) {
    &:hover,
    &:active {
      color: ${({ theme }) => (theme === 'light' ? colors.white : colors.buttonHover)};
      text-decoration: underline;

      &::after {
        animation-duration: 0.3s;
        animation-name: ${tertArrowRightAnimation};
        border-color: ${({ theme }) => (theme === 'light') ? 'none' : colors.buttonHover};
      }
    }
  }

  ${({ theme, parentHovered }) => parentHovered && css`
    @media (hover: hover) {
      color: ${theme === 'light' ? colors.white : colors.buttonHover};

      &::after {
        animation-duration: .3s;
        animation-name: ${tertArrowRightAnimation};
        animation-fill-mode: both;
      }
    }
  `}

  &[download] {
    padding-right: 0;

    ::after {
      position: static;
      top: auto;
      left: auto;
      display: inline-block;
      width: 20px;
      height: 20px;
      background-image: ${({ theme }) => (theme === 'light')
        ? `url(${downloadBarBlueberry}), url(${downloadArrowBlueberry}), ${splitGradient(colors.white, colors.white)}`
        : `url(${downloadBarWhite}), url(${downloadArrowWhite}), ${splitGradient(colors.buttonMain, colors.buttonHover)}`};
      background-position: center, center, left;
      background-size: 10px 10px, 10px 10px, 200% 100%;
    }

    &:hover:after {
      animation-name: ${tertDownloadAnimation};
    }

    ${({ parentHovered }) => parentHovered && css`
      @media (hover: hover) {
        &::after {
          animation-name: ${tertDownloadAnimation};
        }
      }
    `}
  }

  &[disabled] {
    pointer-events: none;
    color: ${colors.slate};

    &::after {
      background-image: url(${arrowRightWhite});
      background-color: ${colors.slate};
    }

    &[download]&::after {
      background-image: url(${downloadBarWhite}), url(${downloadArrowWhite});
    }
  }

  ${({ external, theme, parentHovered }) => external && css`
    &::after {
      position: static;
      top: auto;
      left: auto;
      display: block;
      content: "";
      width: 44px;
      
      background-image: ${(theme === 'light')
        ? `url(${externalDotBlueberry}), url(${externalArrowBlueberry})`
        : `url(${circleArrow})`
      };
      border: ${(theme === 'light') ? '0' : `1px solid ${colors.ruby}`};

      background-repeat: no-repeat;
      background-color: ${colors.white};
      border-radius: 50%;
      background-position: center, center;
      background-size: 20px, 20px, 20px, 20px;
      width: 20px;
      height: 20px;
    }
    @media (hover: hover) {
      &:hover {
        &::after {
          animation-duration: .4s;
          animation-name: ${externalAnimation};
        }
      }
    }

    ${parentHovered && css`
      @media (hover: hover) {
        &::after {
          animation-duration: .4s;
          animation-name: ${externalAnimation};
        }
      }
    `}
  `}
`;


export default TertiaryCss;