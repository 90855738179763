import React, { useState, useEffect, useContext } from 'react';
import Helmet from 'react-helmet';
import FocusTrap from 'focus-trap-react';

// Components
import HeaderLink from './HeaderLink';

// Styles
import * as s from './styles';
import Button from '../Button';
import NavigationDropDown from '../NavigationDropDown';

// Copy
import headerCopy from '../../copy/header';


const ModalAlert =({ link, onClose }) => {
  const handleConfirm = () => {
    if (!link.match(/^https?:\/\//i)) {
      link = 'http://' + link;
    }
    window.open(link, '_blank');
    onClose();
  };

  return (
    <s.ModalPopUp>
      <FocusTrap >
        <s.ModalContent tabIndex="1">
        <s.ModalClose onClick={onClose} title="stay on current site and close this modal">CLOSE</s.ModalClose>
          <p>You will now be leaving this site and will be redirected to the Merck Medical Portal.</p>
          <p>The Merck Medical Portal is for health care professionals in the US and its territories only.</p>
          <s.LightBlueButton variant='primary' className={'modal-button'} tabIndex="0" title="confirm you want to move to another website" onClick={handleConfirm}>Continue<s.ArrowWhiteBtn/></s.LightBlueButton>
        </s.ModalContent>
      </FocusTrap >
      </s.ModalPopUp>
  );
};

const Header = ({pageKey, location}) => {
  const [navOpen, setNavOpen] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [modalLink, setModalLink] = useState('');

  const onOverlayClick = (e) => {
    e.stopPropagation();
    setNavOpen(false);
  };

  const handleLinkClick = (e) => {
    // special "parent" case for svgs - the <a> tag is a parent to <rect> which the event passes in here
    const isExternal = e.target.dataset.showModal || e.target.parentNode.dataset.showModal;
    if (isExternal){
    e.preventDefault();

    if (e.target.href) {
      setModalLink(e.target.href);
    } else if (e.target.parentNode.href.baseVal) {
      // case for <a> tags in an svg
      setModalLink(e.target.parentNode.href.baseVal);
    }
    setShowModal(true);
    }
  };

  useEffect(() => {
    const externalLinks = document.querySelectorAll('a[href^="http"]');
    externalLinks.forEach((link) => {
      link.addEventListener('click', handleLinkClick);
    });

    return () => {
      externalLinks.forEach((link) => {
        link.removeEventListener('click', handleLinkClick);
      });
    };
  }, [location]);

  const handleCloseModal = () => {
    setShowModal(false);
    setModalLink('');
  };

  const handleIndicationDropdownChange = (e) => {
    window.location = e.href;
  };

  return (
  <>
    {navOpen &&
      <Helmet>
        <html lang="en-us" className="no-scroll" />
      </Helmet>
    }

    {showModal &&
      <ModalAlert link={modalLink} onClose={handleCloseModal} />
    }
    <s.StyledHeader className={navOpen ? 'open' : ''}>
      <s.TopNav className="top-nav">
        <s.TopNavWrapper>
          <s.TopNavLinkGroup>
            {
              headerCopy.utilLinks.left.filter(linkObj => linkObj.keys.includes(pageKey)).map((linkObj, i) => (
                <s.TopNavLink
                  key={i}
                  href={linkObj.href}
                  title={linkObj.title}
                  data-show-modal={linkObj.showModal}
                  target="_blank"
                >
                  { linkObj.copy}
                </s.TopNavLink>
              ))
            }
          </s.TopNavLinkGroup>

          <s.TopNavLinkGroupRight desktopOnly>
            {
              headerCopy.utilLinks.right.filter(linkObj => linkObj.keys.includes(pageKey)).map((linkObj, i) => (
                <Button
                  key={i}
                  variant='tertiary'
                  theme="light"
                  to={ linkObj.href }
                  onClick={i === 1 ? handleLinkClick : undefined}
                  title={ linkObj.title }
                  data-show-modal={linkObj.showModal}
                >
                  { linkObj.copy }
                </Button>
              ))
            }
          </s.TopNavLinkGroupRight>
        </s.TopNavWrapper>
      </s.TopNav>

      <s.HeaderBar>
        <s.HeaderBarWrapper>
          <s.LogoLink
            to="/"
          >
            <img src={headerCopy.logoImg} alt={headerCopy.logoAlt} className="main-logo" />
            <div className="non-brand-logo">
               {headerCopy.vhlLogoText}
            </div>
          </s.LogoLink>

          <s.MobileNavOverlay
            isActive={navOpen}
            onClick={onOverlayClick}
          />

          <s.NavTray className={navOpen ? 'open' : ''}>
            <s.MobileLogoLink
              to="/"
              onClick={onOverlayClick}
            >
              <img src={headerCopy.logoImg} alt={headerCopy.logoAlt} className="main-logo" />
              <div className="non-brand-logo">
                {headerCopy.vhlLogoText}
              </div>
            </s.MobileLogoLink>
            {
                headerCopy.navLinks.filter(navItem => navItem.type && navItem.type === 'dropdown' && navItem.keys.includes(pageKey)).map((navItem, i) => 
                  (
                    <NavigationDropDown pageKey={pageKey} key={i} navItem={navItem} handleIndicationDropdownChange={handleIndicationDropdownChange}></NavigationDropDown>
                  )
            )}
            <s.PrimaryNav>
              {
                headerCopy.navLinks.filter(navItem => navItem.keys.includes(pageKey)).map((navItem, i) => {
                  if (!navItem.type || navItem.type !== 'dropdown') {
                    return (
                      <HeaderLink
                        key={i}
                        to={ navItem.href }
                        onClick={onOverlayClick}
                        data-show-modal={navItem.showModal}
                      >
                        { navItem.label }
                      </HeaderLink>
                    )
                  }
                })
              }
            </s.PrimaryNav>
            <s.MobileUtilLinks>
              <Button variant='primaryBlue' externalInverted2 margin to={ headerCopy.utilLinks.right[0].href } data-show-modal={headerCopy.utilLinks.right[0].showModal}>
                { headerCopy.utilLinks.right[0].copy }
              </Button>
              <Button variant='primaryBlue' externalInverted2 margin onClick={handleLinkClick}  to={ headerCopy.utilLinks.right[1].href } data-show-modal={headerCopy.utilLinks.right[1].showModal}>
                { headerCopy.utilLinks.right[1].copy }
                
              </Button>
              <Button variant='primaryBlue' externalInverted2 margin to={ headerCopy.utilLinks.right[2].href } data-show-modal={headerCopy.utilLinks.right[2].showModal}>
                { headerCopy.utilLinks.right[2].copy }
              </Button>
            </s.MobileUtilLinks>
          </s.NavTray>

          <s.HamburgerButton
            onClick={() => setNavOpen(!navOpen)}
            className={navOpen ? 'open' : ''}
          >
            { navOpen ? headerCopy.hamburgerLabels.whileOpen : headerCopy.hamburgerLabels.whileClosed }
          </s.HamburgerButton>

        </s.HeaderBarWrapper>
      </s.HeaderBar>
    </s.StyledHeader>
  </>
  );
};


export default Header;
