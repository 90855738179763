import React from 'react';
import PropTypes from 'prop-types';
import * as s from './styles';


const PageSection = ({
  tall = false,
  short = false,
  flat = false,
  btmShort = false,
  btmFlat = false,
  narrow = false,
  grey = false,
  center,
  children,
  id,
  className,
  tricolumns,
  sectionWrapperId,
}) => {
  return (
    <s.PageSectionEl
      grey={grey}
      tall={tall}
      short={short}
      flat={flat}
      btmShort={btmShort}
      btmFlat={btmFlat}
      className={className}
      id={id}
    >
      <s.SectionWrapper
        narrow={narrow}
        center={center}
        tricolumns={tricolumns}
        id={sectionWrapperId}
      >
        {children}
      </s.SectionWrapper>
    </s.PageSectionEl>
  );
};


PageSection.propTypes = {
  tall: PropTypes.bool,
  short: PropTypes.bool,
  flat: PropTypes.bool,
  narrow: PropTypes.bool,
  grey: PropTypes.bool,
};


export default PageSection;