import React, { useState, useEffect, useRef } from 'react';
import Helmet from 'react-helmet';
import { CSSTransition } from 'react-transition-group';
import throttle from 'lodash.throttle';
import { addLoopedFocus } from '../../../util/accessibility';

import SafetyList from '../SafetyList';

import * as s from './styles';
import * as ps from '../styles';
//Icon
import icon_plus_small from '../../../assets/images/icon-plus-small.svg';

import IndicationCopy from '../../../copy/safety-panel/indication';
import SafetyCopy from '../../../copy/safety-panel/safety';

const HASH_TRIGGER = '#ssi-safety';


const IndicationAndSafetyDrawer = ({ location }) => {
  const safetyRef = useRef(null);
  const indicationRef = useRef(null);

  const [safetyDrawerOpen, setSafetyDrawerOpen] = useState(false);
  const [indicationDrawerOpen, setIndicationDrawerOpen] = useState(false);
  const [showSsiJobCode, setShowSsiJobCode] = useState(false);

  const [safetyPosition, setSafetyPosition] = useState(0);
  const [scrolledPast, setScrolledPast] = useState(false);
  const [overrideCcpaStyle, setOverrideCcpaStyle] = useState(false);

  // Check hash on load
  //----
  useEffect(() => {
    if (location.hash.includes(HASH_TRIGGER)) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
      setShowSsiJobCode(true);
      openSafety();
    }
  }, [location]);

  const hideCcpaBannerIfPresent = () => {
    const ccpaBanner = document.querySelector('#onetrust-banner-sdk');
    if (ccpaBanner){
      ccpaBanner.style.zIndex = '-1';
      setOverrideCcpaStyle(true);
    }
  };

  const showCcpaBanner = () => {
    const ccpaBanner = document.querySelector('#onetrust-banner-sdk');
    if (ccpaBanner && overrideCcpaStyle){
      ccpaBanner.style.zIndex = '2147483645';
      setOverrideCcpaStyle(false);
    }
  };


  // Drawer Toggle Controls
  //----
  const openIndications = () => {
    hideCcpaBannerIfPresent();
    setIndicationDrawerOpen(true);

    if (indicationRef.current) {
      addLoopedFocus(indicationRef.current);
    }
  };

  const openSafety = () => {
    hideCcpaBannerIfPresent();
    setSafetyDrawerOpen(true);

    if (safetyRef.current) {
      addLoopedFocus(safetyRef.current);
    }
  };

  const closeDrawers = () => {
    setSafetyDrawerOpen(false);
    setIndicationDrawerOpen(false);
    showCcpaBanner();
    if (showSsiJobCode) {
      setShowSsiJobCode(false);
    }
  };


  // Scroll Watch
  //----
  const saveSafetyPosition = () => {
    const safetyCopyEl = document.querySelector('#safety-scroll-target');
    if (safetyCopyEl) {
      setSafetyPosition(safetyCopyEl.offsetTop);
    }
  };

  const safetyScrollWatch = () => {
    const currentPageBottomPosition = window.scrollY + window.innerHeight - 80;
    setScrolledPast(currentPageBottomPosition > safetyPosition);
  };
  const throttledSafetyScrollWatch = throttle(safetyScrollWatch, 600);

  const handleKeyUp = (event) => {
    if (event.key === 'Escape' || event.keyCode === 27) {
      closeDrawers();
    }
  };

  useEffect(() => {
    saveSafetyPosition();

    if (safetyPosition) {
      window.addEventListener('scroll', throttledSafetyScrollWatch);

      return () => {
        window.removeEventListener('scroll', throttledSafetyScrollWatch);
      };
    }
  });

  useEffect(() => {
      document.addEventListener('keyup', handleKeyUp);
      window.addEventListener('resize', () => {
        if (safetyDrawerOpen || indicationDrawerOpen){
          hideCcpaBannerIfPresent();
        }
      });

      return () => {
        document.removeEventListener('keyup', handleKeyUp);
        window.removeEventListener('resize', () => {
          if (safetyDrawerOpen || indicationDrawerOpen){
            hideCcpaBannerIfPresent();
          }
        });
      };
  });
  //----


  return (
    <>
      {(indicationDrawerOpen || safetyDrawerOpen) &&
        <Helmet>
          <html lang="en-us" className="no-scroll" />
        </Helmet>
      }

      <s.LaunchButtonContainer isCollapsed={scrolledPast}>

        <s.IndicationsLaunchButton onClick={openIndications}>
          <header>
                <s.PreviewTitle>{ IndicationCopy.title }</s.PreviewTitle>
                <button aria-label={`More information about ${IndicationCopy.title}`} aria-expanded={indicationDrawerOpen}>
                    <img src={icon_plus_small} alt={' '} />
                </button>
          </header>
          <div>
            <s.PreviewText>{ IndicationCopy.body }</s.PreviewText>
          </div>
        </s.IndicationsLaunchButton>

        <CSSTransition
          in={indicationDrawerOpen}
          timeout={400}
          classNames="information-drawer"
        >
          <s.InformationDrawer
            isOpen={indicationDrawerOpen}
            drawerName="indications"
            ref={indicationRef}
          >
            <s.DrawerHeader>
              <s.DrawerHeaderText>
                { IndicationCopy.title }
              </s.DrawerHeaderText>
              <s.ModalButtonWrapper>
                <s.CloseButton
                  onClick={closeDrawers}
                  type="button"
                  aria-label="close"
                >CLOSE</s.CloseButton>
              </s.ModalButtonWrapper>
            </s.DrawerHeader>

            <div className="container">
              <ps.IsiBody>
                <p>{ IndicationCopy.body }</p>
                <p>{ IndicationCopy.body2 }</p>
              </ps.IsiBody>
            </div>

          </s.InformationDrawer>
        </CSSTransition>

        <s.SafetyLaunchButton onClick={openSafety}>
          <header>
            <s.PreviewTitle>{ SafetyCopy.title }</s.PreviewTitle>
                <button aria-label={`More information about ${SafetyCopy.title}`} aria-expanded={safetyDrawerOpen}>
                    <img  src={icon_plus_small} alt={' '} />
                </button>
          </header>
          <div>
            <ps.SafetyWarningTitle flat>
              { SafetyCopy.warningList.title }
            </ps.SafetyWarningTitle>

            <ps.SafetyWarningList flat>
              {SafetyCopy.warningList.items.map((item, i) => (
                <li key={i}>{ item }</li>
              ))}
            </ps.SafetyWarningList>
          </div>
        </s.SafetyLaunchButton>

        <CSSTransition
          in={safetyDrawerOpen}
          timeout={400}
          classNames="information-drawer"
        >
          <s.InformationDrawer
            isOpen={safetyDrawerOpen}
            drawerName="safety"
            ref={safetyRef}
          >
            <s.DrawerHeader>
              <s.DrawerHeaderText>
                { SafetyCopy.title }
              </s.DrawerHeaderText>
              <s.ModalButtonWrapper>
                <s.CloseButton
                  onClick={closeDrawers}
                  type="button"
                  aria-label="close"
                >CLOSE</s.CloseButton>
              </s.ModalButtonWrapper>
            </s.DrawerHeader>

            <div className="container">
              <SafetyList showSsiJobCode={showSsiJobCode}/>
            </div>
          </s.InformationDrawer>
        </CSSTransition>

      </s.LaunchButtonContainer>

      <s.DrawerOverlay
        isActive={safetyDrawerOpen || indicationDrawerOpen}
        onClick={closeDrawers}
      />
    </>
  );
};


export default IndicationAndSafetyDrawer;
