// import pn100w from '../../assets/fonts/proxima-nova-100.woff';
// import pn100w2 from '../../assets/fonts/proxima-nova-100.woff2';
// import pn100iw from '../../assets/fonts/proxima-nova-100-i.woff';
// import pn100iw2 from '../../assets/fonts/proxima-nova-100-i.woff2';

import pn300w from '../../assets/fonts/proxima-nova-300.woff';
import pn300w2 from '../../assets/fonts/proxima-nova-300.woff2';
import pn300iw from '../../assets/fonts/proxima-nova-300-i.woff';
import pn300iw2 from '../../assets/fonts/proxima-nova-300-i.woff2';

import pn400w from '../../assets/fonts/proxima-nova-400.woff';
import pn400w2 from '../../assets/fonts/proxima-nova-400.woff2';
import pn400iw from '../../assets/fonts/proxima-nova-400-i.woff';
import pn400iw2 from '../../assets/fonts/proxima-nova-400-i.woff2';

import pn500w from '../../assets/fonts/proxima-nova-500.woff';
import pn500w2 from '../../assets/fonts/proxima-nova-500.woff2';
// import pn500iw from '../../assets/fonts/proxima-nova-500-i.woff';
// import pn500iw2 from '../../assets/fonts/proxima-nova-500-i.woff2';

// import pn600w from '../../assets/fonts/proxima-nova-600.woff';
// import pn600w2 from '../../assets/fonts/proxima-nova-600.woff2';
// import pn600iw from '../../assets/fonts/proxima-nova-600-i.woff';
// import pn600iw2 from '../../assets/fonts/proxima-nova-600-i.woff2';

import pn700w from '../../assets/fonts/proxima-nova-700.woff';
import pn700w2 from '../../assets/fonts/proxima-nova-700.woff2';
// import pn700iw from '../../assets/fonts/proxima-nova-700-i.woff';
// import pn700iw2 from '../../assets/fonts/proxima-nova-700-i.woff2';

// import pn800w from '../../assets/fonts/proxima-nova-800.woff';
// import pn800w2 from '../../assets/fonts/proxima-nova-800.woff2';
// import pn800iw from '../../assets/fonts/proxima-nova-800-i.woff';
// import pn800iw2 from '../../assets/fonts/proxima-nova-800-i.woff2';

import pn900w from '../../assets/fonts/proxima-nova-900.woff';
import pn900w2 from '../../assets/fonts/proxima-nova-900.woff2';
// import pn900iw from '../../assets/fonts/proxima-nova-900-i.woff';
// import pn900iw2 from '../../assets/fonts/proxima-nova-900-i.woff2';


const FontStyles = `

/* Light */
@font-face {
    font-family: 'proxima-nova';
    src: url('${pn300w2}') format('woff2'),
         url('${pn300w}') format('woff');
    font-style: normal;
    font-weight: 300;
}

/* Light Italic */
@font-face {
    font-family: 'proxima-nova';
    src: url('${pn300iw2}') format('woff2'),
         url('${pn300iw}') format('woff');
    font-style: italic;
    font-weight: 300;
}

/* Regular */
@font-face {
    font-family: 'proxima-nova';
    src: url('${pn400w2}') format('woff2'),
         url('${pn400w}') format('woff');
    font-style: normal;
    font-weight: 400;
}

/* Regular Italic */
@font-face {
    font-family: 'proxima-nova';
    src: url('${pn400iw2}') format('woff2'),
         url('${pn400iw}') format('woff');
    font-style: italic;
    font-weight: 400;
}

/* Medium */
@font-face {
    font-family: 'proxima-nova';
    src: url('${pn500w2}') format('woff2'),
         url('${pn500w}') format('woff');
    font-style: normal;
    font-weight: 500;
}

/* Bold */
@font-face {
    font-family: 'proxima-nova';
    src: url('${pn700w2}') format('woff2'),
         url('${pn700w}') format('woff');
    font-style: normal;
    font-weight: 700;
}

/* Bold */
@font-face {
    font-family: 'proxima-nova';
    src: url('${pn900w2}') format('woff2'),
         url('${pn900w}') format('woff');
    font-style: normal;
    font-weight: 900;
}

`;


export default FontStyles;



// /* Thin */
// @font-face {
//     font-family: 'proxima-nova';
//     src: url('${pn100w2}') format('woff2'),
//          url('${pn100w}') format('woff');
//     font-style: normal;
//     font-weight: 100;
// }

// /* Thin Italic */
// @font-face {
//     font-family: 'proxima-nova';
//     src: url('${pn100iw2}') format('woff2'),
//          url('${pn100iw}') format('woff');
//     font-style: italic;
//     font-weight: 100;
// }

// /* Medium Italic */
// @font-face {
//     font-family: 'proxima-nova';
//     src: url('${pn500iw2}') format('woff2'),
//          url('${pn500iw}') format('woff');
//     font-style: italic;
//     font-weight: 500;
// }

// /* Semi-bold */
// @font-face {
//     font-family: 'proxima-nova';
//     src: url('${pn600w2}') format('woff2'),
//          url('${pn600w}') format('woff');
//     font-style: normal;
//     font-weight: 600;
// }

// /* Semi-bold Italic */
// @font-face {
//     font-family: 'proxima-nova';
//     src: url('${pn600iw2}') format('woff2'),
//          url('${pn600iw}') format('woff');
//     font-style: italic;
//     font-weight: 600;
// }

// /* Bold Italic */
// @font-face {
//     font-family: 'proxima-nova';
//     src: url('${pn700iw2}') format('woff2'),
//          url('${pn700iw}') format('woff');
//     font-style: italic;
//     font-weight: 700;
// }

// /* Extra Bold */
// @font-face {
//     font-family: 'proxima-nova';
//     src: url('${pn800w2}') format('woff2'),
//          url('${pn800w}') format('woff');
//     font-style: normal;
//     font-weight: 800;
// }

// /* Extra Bold Italic */
// @font-face {
//     font-family: 'proxima-nova';
//     src: url('${pn800iw2}') format('woff2'),
//          url('${pn800iw}') format('woff');
//     font-style: italic;
//     font-weight: 800;
// }

// /* Black */
// @font-face {
//     font-family: 'proxima-nova';
//     src: url('${pn900w2}') format('woff2'),
//          url('${pn900w}') format('woff');
//     font-style: normal;
//     font-weight: 900;
// }

// /* Black Italic */
// @font-face {
//     font-family: 'proxima-nova';
//     src: url('${pn900iw2}') format('woff2'),
//          url('${pn900iw}') format('woff');
//     font-style: italic;
//     font-weight: 900;
// }