import { css } from 'styled-components';
import * as colors from '../../../styles/shared/colors';
import downloadArrowBlueberry from '../../../assets/icon-colors/download-arrow-blue.svg';
import downloadArrowBlueberryDark from '../../../assets/icon-colors/download-arrow-blue-dark.svg';
import downloadArrowWhite from '../../../assets/icon-colors/download-arrow-white.svg';
import downloadBarBlueberry from '../../../assets/icon-colors/download-bar-blue.svg';
import downloadBarBlueberryDark from '../../../assets/icon-colors/download-bar-blue-dark.svg';
import downloadBarWhite from '../../../assets/icon-colors/download-bar-white.svg';
import arrowRightWhite from '../../../assets/icon-colors/arrow-right-white2.svg';
import arrowRightBlue from '../../../assets/icon-colors/arrow-right-blue2.svg';
import externalCircle from '../../../assets/icon-colors/external-circle.svg';
import externalCircleBlue from '../../../assets/icon-colors/external-circle-blue.svg';
import iconLinkInternal from '../../../assets/icon-colors/icon-link-internal.svg';
import externalArrowWhiteWithDots from '../../../assets/icon-colors/external-arrow-white-with-dots.svg';
import arrowRightCircleWhite from '../../../assets/icon-colors/arrow-right-circle-white.svg';
import { respondTo } from '../../../styles/shared/breakpoints';


const Primary2Css = css`
  color: ${colors.white};
  background: ${colors.primaryButtonBlue};
  border-radius: 4px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  

   /* TODO - import from typygraphy once updated */
   font-size: 16px;
   font-weight: 600;
   line-height: 20px;

  @media (hover:hover) {
    &:hover,
    &:active {
      background-position: right center;
      color: ${({ theme }) => (theme === 'light' ? colors.buttonHover : colors.brandBlueDark)};
      background: ${colors.white};
      outline: 1px solid ${colors.primaryButtonBlue};

    }
  }

  /* DOWNLOAD BUTTON */
  &[download] {
    &::after {
      background-color: ${({ theme }) => (theme === 'light') ? colors.mist : colors.rubyDark};
      background-image: ${({ theme }) => (theme === 'light')
        ? `url(${downloadBarBlueberry}), url(${downloadArrowBlueberry})`
        : `url(${downloadBarWhite}), url(${downloadArrowWhite})`};

      @media (hover:hover) {
        &:hover {
          &::after {
            background-image: ${({ theme }) => (theme === 'light')
              ? `url(${downloadBarBlueberryDark}), url(${downloadArrowBlueberryDark})`
              : `url(${downloadBarWhite}), url(${downloadArrowWhite})`};
          }
        }
      }
    }

    @media (hover:hover) {
      &:hover {
        &::after {
          background-color: ${colors.buttonHover};
        }
      }
    }
  }

  /* DISABLED BUTTON */
  &[disabled] {
    background-color: ${colors.slate};
    color: ${colors.white};

    &[download]&::after {
      background-image: url(${downloadBarWhite}), url(${downloadArrowWhite});
      background-color: ${colors.concrete};
    }
  }


  /* EXTERNAL BUTTON */
  ${({ external }) => external && css`
    padding-right: 46px;

    &::after {
      background-image: ${`url(${externalCircle})`};
      background-size: 20px 20px;
    }
    @media (hover: hover) {
      &:hover {
        &::after {
              background-image: ${`url(${externalCircleBlue})`};
        }
      }
    }
  `}

  /* EXTERNAL BUTTON */
  ${({ external2 }) => external2 && css`
    padding-right: 46px;

    justify-content: flex-start;

    &::after {
      background-image: url(${externalArrowWhiteWithDots});
      background-size: 20px 20px;
    }

    @media (hover: hover) {
      &:hover {
        &::after {
              background-image: ${`url(${externalCircleBlue})`};
        }
      }
    }

  `}  

  /* EXTERNAL INVERTED BUTTON */
  ${({ externalInverted }) => externalInverted && css`
    padding-right: 46px;
    background: ${colors.white};
    color: ${colors.primaryButtonBlue};

    font-size: 18px;
    font-weight: 700;
    line-height: 130%;

    &::after {
      background-image: ${`url(${externalCircleBlue})`};
      background-size: 20px 20px;
    }

    @media (hover: hover) {
      &:hover {
        &::after {
          background-image: ${`url(${externalCircle})`};
        }
        color: ${colors.white};
        background: ${colors.brandBlueDark};
      }
    }
  `}

  /* EXTERNAL INVERTED BUTTON - version 2 - used for mobile nav */
  ${({ externalInverted2 }) => externalInverted2 && css`
    padding-right: 8px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 0px;
    width: 195px;
    font-size: 18px;

    background: ${colors.white};
    color: ${colors.charcoal2};
    justify-content: flex-start;

    &::after {
      background-image: ${`url(${externalCircleBlue})`};
      background-size: 20px 20px;
    }

    @media (hover: hover) {
      &:hover {
        background: ${colors.brandBlueDark};
        color: ${colors.white};
        &::after {
          background-image: ${`url(${externalCircle})`};
        }
      }
    }
  `}  

  /* CONTINUAL BUTTON */
  ${({ continual }) => continual && css`
    &::after {
      background-image: ${`url(${arrowRightWhite})`};
      background-color: ${colors.primaryButtonBlue};
    }

    @media (hover: hover) {
      &:hover {
        &::after {
          background-image: ${`url(${arrowRightBlue})`};
          background-color: ${colors.white};
        }
      }
    }
  `}

  /* CONTINUAL INVERTED BUTTON */
  ${({ continualInverted }) => continualInverted && css`
    background-color: ${colors.white};
    color: ${colors.brandBlueDark};
    font-weight: 700;
    
    &::after {
      background-image: ${`url(${arrowRightBlue})`};
    }
    
    @media (hover: hover) {
      &:hover {
        background: ${colors.brandBlueDark};
        color: ${colors.white};
        &::after {
          background-image: ${`url(${arrowRightWhite})`};
        }
      }
    }
  `}

  /* BLUE-CIRCLE-BACKGROUND CONTINUAL BUTTON */
  ${({ bcbContinual }) => bcbContinual && css`
    font-size: 18px;
    font-weight: 700;
    line-height: 130%;
    background: ${colors.white};
    color: ${colors.brandBlueDark};
    max-width: 280px;
    text-align: left;

    ${respondTo.sm`
      max-width: unset;
    `}


    &::after {
      background-image: ${`url(${iconLinkInternal})`};
      background-size: 20px 20px;
    }

    @media (hover: hover) {
      &:hover {
        &::after {
          background-image: ${`url(${arrowRightCircleWhite})`};
        }
        color: ${colors.white};
        background: ${colors.brandBlueDark};
      }
    }

  `}

`;


export default Primary2Css;