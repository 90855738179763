import { css } from 'styled-components';
import * as colors from '../../../styles/shared/colors';
import { splitGradient } from './BaseBtn';

import downloadArrowBlueberry from '../../../assets/icon-colors/download-arrow-blue.svg';
import downloadArrowBlueberryDark from '../../../assets/icon-colors/download-arrow-blue-dark.svg';
import downloadArrowWhite from '../../../assets/icon-colors/download-arrow-white.svg';
import downloadBarBlueberry from '../../../assets/icon-colors/download-bar-blue.svg';
import downloadBarBlueberryDark from '../../../assets/icon-colors/download-bar-blue-dark.svg';
import downloadBarWhite from '../../../assets/icon-colors/download-bar-white.svg';
import externalArrowBlueberry from '../../../assets/icon-colors/external-arrow-blue.svg';
import externalArrowBlueberryDark from '../../../assets/icon-colors/external-arrow-blue-dark.svg';
import externalArrowWhite from '../../../assets/icon-colors/external-arrow-white.svg';
import externalDotBlueberry from '../../../assets/icon-colors/external-dot-blue.svg';
import externalDotBlueberryDark from '../../../assets/icon-colors/external-dot-blue-dark.svg';
import externalDotWhite from '../../../assets/icon-colors/external-dot-white.svg';



const PrimaryCss = css`
  color: ${({ theme }) => (theme === 'light' ? colors.buttonMain : colors.white)};
  background-image: ${({ theme }) => (theme === 'light')
    ? splitGradient(colors.white, colors.mist)
    : splitGradient(colors.buttonMain, colors.buttonHover)};

  @media (hover:hover) {
    &:hover,
    &:active {
      background-position: right center;
      color: ${({ theme }) => (theme === 'light' ? colors.buttonHover : colors.white)};
    }
  }

  &[download] {
    &::after {
      background-color: ${({ theme }) => (theme === 'light') ? colors.mist : colors.rubyDark};
      background-image: ${({ theme }) => (theme === 'light')
        ? `url(${downloadBarBlueberry}), url(${downloadArrowBlueberry})`
        : `url(${downloadBarWhite}), url(${downloadArrowWhite})`};

      @media (hover:hover) {
        &:hover {
          &::after {
            background-image: ${({ theme }) => (theme === 'light')
              ? `url(${downloadBarBlueberryDark}), url(${downloadArrowBlueberryDark})`
              : `url(${downloadBarWhite}), url(${downloadArrowWhite})`};
          }
        }
      }
    }

    @media (hover:hover) {
      &:hover {
        &::after {
          background-color: ${colors.buttonHover};
        }
      }
    }
  }

  &[disabled] {
    background-color: ${colors.slate};
    color: ${colors.white};

    &[download]&::after {
      background-image: url(${downloadBarWhite}), url(${downloadArrowWhite});
      background-color: ${colors.concrete};
    }
  }

  ${({ external, theme }) => external && css`
    &::after {
      background-image: ${ (theme === 'light')
        ? `url(${externalDotBlueberry}), url(${externalArrowBlueberry})`
        : `url(${externalDotWhite}), url(${externalArrowWhite})`
      };
      background-color: ${theme === 'light' ? colors.mist : colors.rubyDark};
    }
    @media (hover:hover) {
      &:hover {
        &::after {
          background-color: ${colors.buttonHover};
          background-image: ${
            theme === 'light'
                ? `url(${externalDotBlueberryDark}), url(${externalArrowBlueberryDark})`
                : `url(${externalDotWhite}), url(${externalArrowWhite})`
          };
        }
      }
    }
  `}
`;


export default PrimaryCss;