import * as React from 'react';

// Components
import Header from '../Header';
import Footer from '../Footer';
import IndicationAndSafetyDrawer from '../IndicationAndSafety/Drawer/IndicationAndSafetyDrawer';
import IndicationAndSafety from '../IndicationAndSafety';
import WelcomeModal from '../WelcomeModal';
import ScrollToTop from '../ScrollToTop';
// Styles
import GlobalStyles from '../../styles/global';
import { StyledLayout, PageMain } from './styles';
import PageSeo from '../Seo/PageSeo';


const PageLayout = ({ children, location, pageMeta }) => {

  let pageKey = 'default';
  switch(location.pathname.split('/')[1].toLowerCase()) {

    case 'von-hippel-lindau':
      pageKey = 'vhl';
      break;
    case 'advanced-renal-cell-carcinoma':
    case 'component-examples':
      pageKey = 'rcc';
      break;
    case '':
      pageKey = 'mt';
      break;
    case 'contact-us':
      pageKey = 'ut'
      break;
    case '404':
    case 'site-map':
      pageKey = 'ut';
      break;
  }

  return (
    <StyledLayout>
      <PageSeo pageMeta={pageMeta} />
      <GlobalStyles />

      <Header pageKey={pageKey} location={location} />
      <IndicationAndSafetyDrawer location={location} />
      <PageMain>
        {children}
      </PageMain>
      <IndicationAndSafety />

      <ScrollToTop />

      <Footer pageKey={pageKey} />

      <WelcomeModal />
      
    </StyledLayout>
  );
};


export default PageLayout;