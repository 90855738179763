import styled from 'styled-components';
import { respondTo } from '../../styles/shared/breakpoints';

export const StyledLayout = styled.div`
  width: 100%;
  min-height: 100vh;
  /* overflow: hidden; */
`;

export const PageMain = styled.main`
  /* max-height: 100vh;
  overflow-y: scroll; */

  ${respondTo.sm`
    overflow: unset;
    max-height: unset;
  `}
`;