import React from 'react';
import LogoPath from '../assets/images/logo-welireg.svg';
import eaPath from '../assets/images/logo-ea-white.svg';
import merckPath from '../assets/images/logo-merck-white.svg';

const footerCopy = {
  footerLogo: {
    img: LogoPath,
    alt: 'WELIREG® (belzutifan) Logo',
  },
  footerUtilLinks: {
    patientSite: {
        copy: 'Visit Patient Site',
        href: 'https://www.welireg.com/',
        keys: ['vhl', 'rcc', 'ut', 'mt']
    },
    contactUs : {
      copy: 'Contact Us',
      href: '/contact-us',
      keys: ['vhl', 'rcc', 'mt', 'ut']
    },
  },
  footerSiteLinks: {
    col1: [
      {
        href: '/advanced-renal-cell-carcinoma/',
        label: 'Home for Advanced RCC',
        keys: ['ut', 'mt']
      },
      {
        href: '/von-hippel-lindau/what-is-von-hippel-lindau',
        label: 'What Is VHL Disease?',
        keys: ['vhl']
      },
      {
        href: '/von-hippel-lindau/mechanism-of-action',
        label: 'Mechanism of Action',
        keys: ['vhl']
      },
      {
        href: '/von-hippel-lindau/efficacy',
        label: 'Efficacy',
        keys: ['vhl']
      },
      {
        label: 'Mechanism of Action',
        href: '/advanced-renal-cell-carcinoma/mechanism-of-action',
        keys: ['rcc']
      },
      {
        label: 'Efficacy',
        href: '/advanced-renal-cell-carcinoma/efficacy',
        keys: ['rcc']
      },  
    ],
    col2: [
      {
        href: '/von-hippel-lindau/',
        label: <>Home for VHL Disease&#8211;associated Tumors</>,
        keys: ['ut', 'mt']
      },
      {
        href: '/von-hippel-lindau/adverse-reaction-profile/',
        label: 'Safety',
        keys: ['vhl']
      },
      {
        href: '/von-hippel-lindau/guidelines',
        label: 'Guidelines',
        keys: ['vhl']
      },
      {
        label: 'Treatment Sequencing',
        href: '/advanced-renal-cell-carcinoma/treatment-considerations',
        keys: ['rcc']
      },
      {
        label: 'Safety',
        href: '/advanced-renal-cell-carcinoma/safety',
        keys: ['rcc']
      },      
    ],
    col3: [
      {
        href: '/von-hippel-lindau/dosing',
        label: 'Dosing',
        keys: ['vhl']
      },
      {
        href: '/von-hippel-lindau/resources',
        label: 'Resources & Dispensing',
        keys: ['vhl']
      },
      {
        label: 'Dosing',
        href: '/advanced-renal-cell-carcinoma/dosing',
        keys: ['rcc']
      },
      {
        label: 'Resources & Dispensing',
        href: '/advanced-renal-cell-carcinoma/resources-and-access',
        keys: ['rcc']
      },      
    ],
  },
  subLinks: [
    {
      copy: 'Privacy Policy',
      href: 'https://www.msdprivacy.com/us/en/',
      keys: ['vhl', 'rcc', 'mt', 'ut']
    },
    {
      copy: 'Terms of Use',
      href: 'https://www.merck.com/terms-of-use/',
      keys: ['vhl', 'rcc', 'mt', 'ut']
    },
    {
      copy: 'Site Map',
      href: '/site-map',
      target: '_self',
      keys: ['vhl', 'rcc', 'mt', 'ut']
    },
    {
      copy: 'Cookie Preferences',
      class: 'ot-sdk-show-settings',
      target: '_self',
      href: 'javascript:void(0)',
      keys: ['vhl', 'rcc', 'mt', 'ut']
    },
    {
      copy: 'Accessibility',
      href: 'https://www.msdaccessibility.com/',
      keys: ['vhl', 'rcc', 'mt','ut']
    },
  ],
  footerBottom: {
    left: [
      {
      copy: 'This site is intended for health care professionals of the United States, its territories, and Puerto Rico. Copyright © 2024 Merck & Co., Inc., Rahway, NJ, USA and its affiliates. All rights reserved.',
      subcopy: 'US-WRC-00199 04/24',
      keys: [ 'rcc']
      },
      {
      copy: 'This site is intended for health care professionals of the United States, its territories, and Puerto Rico. Copyright © 2024 Merck & Co., Inc., Rahway, NJ, USA and its affiliates. All rights reserved.',
      subcopy: 'US-BEL-00990 03/24',
      keys: [ 'vhl']
      },
      {
        copy: 'This site is intended for health care professionals of the United States, its territories, and Puerto Rico. Copyright © 2024 Merck & Co., Inc., Rahway, NJ, USA and its affiliates. All rights reserved.',
        subcopy: 'US-BEL-00930 03/24',
        keys: [ 'mt', ]
      },
      {
        copy: 'This site is intended for health care professionals of the United States, its territories, and Puerto Rico. Copyright © 2024 Merck & Co., Inc., Rahway, NJ, USA and its affiliates. All rights reserved.',
        subcopy: 'US-BEL-00930 03/24',
        keys: [ 'ut']
      },
      
      
    ],
    accessibility: {
      href: 'https://www.essentialaccessibility.com/merck/?utm_source=merck-homepage&utm_medium=icon-large&utm_term=eachannel-page&utm_content=header&utm_campiagn=merck',
      img: eaPath,
      alt: 'Level Access Company Logo',
    },
    merckLogo: {
      img: merckPath,
      alt: 'Merck Logo',
    },
  },
};


export default footerCopy;
