import { keyframes } from 'styled-components';


export const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;


// Buttons
export const downloadAnimation = keyframes`
  0%,
  100% {
    background-position: center, center 50%;
  }
  50% {
    background-position: center, center calc(100% + 12px);
  }
  50.001% {
    background-position: center, center calc(0% - 12px);
  }
`;

export const tertDownloadAnimation = keyframes`
  0% {
    background-position: center, center 50%, left;
  }
  50% {
    background-position: center, center calc(100% + 15px), center;
  }
  50.001% {
    background-position: center, center calc(0% - 15px), center;
  }
  100% {
    background-position: center, center 50%, right;
  }
`;

export const externalAnimation = keyframes`
  0%,
  100% {
    background-position: center, center 50%;
  }
  50% {
    background-position: center, calc(100% + 10px) calc(0% - 10px);
  }
  50.001% {
    background-position: center, calc(0% - 10px) calc(100% + 10px);
  }
`;

export const arrowRightAnimation = keyframes`
  0% {
    background-position: center 50%, left;
  }
  50% {
    background-position: calc(100% + 20px) center, center;
  }
  50.001% {
    background-position: calc(0% - 20px) center, center;
  }
  100% {
    background-position: center 50%, right;
  }
`;

export const tertArrowRightAnimation = keyframes`
  0% {
    background-position: center 50%, left;
  }
  50% {
    background-position: calc(100% + 20px) center, left;
  }
  50.001% {
    background-position: calc(0% - 20px) center, left;
  }
  100% {
    background-position: center 50%, left;
  }
`;