import styled, { css } from 'styled-components';

import { NarrowWrapperCss, WideWrapperCss } from '../../styles/shared/layout';
import { respondTo } from '../../styles/shared/breakpoints';
import * as colors from '../../styles/shared/colors';


// Sizes
//----
const padSizes = {
  flat: {
    m: 0,
    d: 0,
  },
  short: {
    m: 16,
    d: 24,
  },
  reg: {
    m: 40,
    d: 40,
  },
  tall: {
    m: 40,
    d: 60,
  },
};


// Helpers
//----
const getMobileBottomPadding = ({btmShort, btmFlat}) => {
  return (btmShort) ? `padding-bottom: ${padSizes.short.m}px;` :
    (btmFlat) ? `padding-bottom: ${padSizes.flat.m};` : '';
};

const getDesktopBottomPadding = ({btmShort, btmFlat}) => {
  return (btmShort) ? `padding-bottom: ${padSizes.short.d}px;` :
    (btmFlat) ? `padding-bottom: ${padSizes.flat.d};` : '';
};

const getPadding = ({tall, short, flat}) => {
  if (tall) {
    return css`
      padding: ${padSizes.tall.m}px 0;
      ${getMobileBottomPadding}

      ${respondTo.sm`
        padding-top: ${padSizes.tall.d}px;
        padding-bottom: ${padSizes.tall.d}px;
        ${getDesktopBottomPadding}
      `}
    `;

  } else if (short) {
    return css`
      padding: ${padSizes.short.m}px 0;
      ${getMobileBottomPadding}

      ${respondTo.sm`
        padding-top: ${padSizes.short.d}px;
        padding-bottom: ${padSizes.short.d}px;
        ${getDesktopBottomPadding}
      `}
    `;

  } else if (flat) {
    return css`
      padding: ${padSizes.flat.m};
    `;

  } else {
    return css`
      padding: ${padSizes.reg.m}px 0;
      ${getMobileBottomPadding}

      ${respondTo.sm`
        padding-top: ${padSizes.reg.d}px;
        padding-bottom: ${padSizes.reg.d}px;
        ${getDesktopBottomPadding}
      `}
    `;
  }
};


// Styles
//----
export const PageSectionEl = styled.section`
  ${getPadding}
  background-color: ${({grey}) => grey ? colors.cloud : colors.white};
`;

export const SectionWrapper = styled.div`
  ${({narrow}) => narrow ? NarrowWrapperCss : WideWrapperCss}
  padding: 0 20px;
  ${({center}) => center ? 'text-align: center;' : ''}
  ${({tricolumns}) => tricolumns ? 'column-count: 3;' : ''}
`;