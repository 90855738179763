export const getFocusableElements = (container) => {
  const focusableSelector = 'a[href]:not([tabindex="-1"]), button:not([disabled]):not([tabindex="-1"]), input:not([disabled]):not([tabindex="-1"]), select:not([disabled]):not([tabindex="-1"]), textarea:not([disabled]):not([tabindex="-1"]), [tabindex]:not([disabled]):not([tabindex="-1"])';
  const focusableElements = container.querySelectorAll(focusableSelector);
  const focusableElementsArray = Array.from(focusableElements);
  return focusableElementsArray;
};

export const addLoopedFocus = (container) => {
  const focusableElements = getFocusableElements(container);

  container.addEventListener('keydown', function(e) {
    const isTabPressed = e.key === 'Tab' || e.keyCode === 9;

    if (!isTabPressed) return;

    if (e.shiftKey) {
      if (document.activeElement === focusableElements[0]) {
        e.preventDefault();
        focusableElements[focusableElements.length - 1].focus();
      }
    } else {
      if (document.activeElement === focusableElements[focusableElements.length - 1]) {
        e.preventDefault();
        focusableElements[0].focus();
      }
    }
  });
};

export default {};