import React, {useEffect, useState} from 'react';
import FocusTrap from 'focus-trap-react';
import Helmet from 'react-helmet';
import { ModalBgContainer, ModalWindow, ModalContent, ModalHeader, ModalBody, ModalDivider, ArrowWhiteBtn, ArrowExternalBtn, LightBlueButton } from './styles';

import welcomeModalCopy from '../../copy/welcome-modal';

const storageId = 'isHCP';


const WelcomeModal = () => {
  const [isHcp, setIsHcp] = useState(true);

  useEffect(() => {
    setIsHcp(window.sessionStorage.getItem(storageId));
  }, []);

  const onPrimaryClick = () => {
    window.sessionStorage.setItem(storageId, 'true');
    setIsHcp(true);
  };

  return (isHcp) ? null : (
    <>
    <Helmet>
      <html lang="en-us" className="no-scroll" />
    </Helmet>

        <FocusTrap focusTrapOptions={{initialFocus: false}}>
      <ModalBgContainer>
        <ModalWindow role="dialog" id="welcome-window" aria-labelledby="welcome-dialog">
          <ModalContent>
            <ModalHeader id="welcome-dialog">{ welcomeModalCopy.header }</ModalHeader>
            <LightBlueButton variant='primary' className={'modal-button'} onClick={onPrimaryClick}>{ welcomeModalCopy.primaryCta }<ArrowWhiteBtn/></LightBlueButton>
            <ModalDivider />
            <LightBlueButton
              variant='primary'
              newWindow={false}
              to={ welcomeModalCopy.secondaryCtaHref }
              className={'modal-button'}
            >
              { welcomeModalCopy.secondaryCta }<ArrowExternalBtn/>
            </LightBlueButton>
            <ModalBody>
              { welcomeModalCopy.body }
            </ModalBody>
          </ModalContent>
        </ModalWindow>
      </ModalBgContainer>
    </FocusTrap>

    </>
  );
};


export default WelcomeModal;