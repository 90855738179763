import React from 'react';
import PageSection from '../PageSection';
import {Button, Arrow} from './styles';

const onScrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
};


const ScrollToTop = () => {
  return (
    <PageSection tall center>
      <Button onClick={onScrollToTop} type="button">
        <Arrow />
        Back to Top
      </Button>
    </PageSection>
  );
};


export default ScrollToTop;