import styled from 'styled-components';
import {grape, weliregBlue} from '../../styles/shared/colors';

export const Arrow = styled.div`
  width: 15px;
  height: 15px;
  border-top: 2px solid ${weliregBlue};
  border-right: 2px solid ${weliregBlue};
  transform: rotate(-45deg);
  margin: 0 auto 4px;
  transition: border 0.3s;
`;

export const Button = styled.button`
  cursor: pointer;
  text-align: center;
  font-size: 14px;
  color: ${weliregBlue};
  font-weight: 500;
  border: 0;
  background-color: transparent;
  transition: 0.3s color;

  &:hover {
    color: ${grape};

    ${Arrow} {
      border-color: ${grape};
    }
  }
`;