import styled, { css } from 'styled-components';
import * as colors from './colors';
import { respondTo } from './breakpoints';

export const WRAPPER_WIDE_MAX_WIDTH = '1024px';
export const WRAPPER_NARROW_MAX_WIDTH = '856px';


// Larger Width (Header content, ISI, etc)
export const WideWrapperCss = css`
  margin: 0 auto;
  width: 100%;
  max-width: ${WRAPPER_WIDE_MAX_WIDTH};
`;

export const WideWrapper = styled.div`
  ${WideWrapperCss};
`;


// Page Content Width
export const NarrowWrapperCss = css`
  width: 100%;
  max-width: ${WRAPPER_NARROW_MAX_WIDTH};
  margin: 0 auto;
  padding: 0 16px;
`;

export const NarrowWrapper = styled.div`
  ${NarrowWrapperCss};
`;


// Divider
export const DashedDivider = styled.div`
  width: 100%;
  height: 1px;
  margin: 22px 0;
  border-top: 2px dashed ${colors.ruby};
  ${respondTo.sm`
    margin: 39px 0;
  `}
`;