import styled from 'styled-components';

import { BaseButton } from './BaseBtn';
import PrimaryCss from './PrimaryCss';
import SecondaryCss from './SecondaryCss';
import TertiaryCss from './TertiaryCss';
import PrimaryBlueCss from './PrimaryBlueCss'

export const StyledButton = styled(BaseButton)`
  ${({ variant }) => variant === 'primary' && PrimaryCss}
  ${({ variant }) => variant === 'secondary' && SecondaryCss}
  ${({ variant }) => variant === 'tertiary' && TertiaryCss}
  ${({ variant }) => variant === 'primaryBlue' && PrimaryBlueCss}
`;