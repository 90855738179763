import React, { useState } from 'react';
import { Listbox } from '@headlessui/react';
import styled from 'styled-components';
import { white, ink2, tintsBlue, brandBlueDark, charcoal2, pebble2 } from '../../styles/shared/colors';
import { respondTo } from '../../styles/shared/breakpoints';

const ListboxContainer = styled.div`
  margin-left: 6px;
  margin-right: 6px;
  margin-bottom: 0px;
  margin-top: 34px;
  height: auto;

  ${respondTo.md`
    margin-left: 20px;
    position: absolute;
    display: flex;
    flex-direction: column;
    height: 50px;
    
    cursor: pointer;
  `}

  ul {
    z-index: 1;
    li {
      border-top: 0.5px solid ${pebble2};

      background: ${white};

      color: ${brandBlueDark};

      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      padding: 15px 10px 15px 10px;

      &:after {
        content: url("data:image/svg+xml,%3Csvg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='Link arrow - internal'%3E%3Cpath id='Vector' d='M8 0.015625C3.5816 0.015625 0 3.59763 0 8.01562C0 12.4336 3.5816 16.0156 8 16.0156C12.4184 16.0156 16 12.434 16 8.01562C16 3.59722 12.4184 0.015625 8 0.015625ZM8.5492 11.8152C8.32 12.0548 7.9404 12.0628 7.7008 11.834C7.4612 11.6048 7.4532 11.2252 7.682 10.9856L9.95 8.61562H4.4364C4.1052 8.61562 3.8364 8.34682 3.8364 8.01562C3.8364 7.68442 4.1052 7.41562 4.4364 7.41562H9.95L7.682 5.04562C7.4528 4.80603 7.4612 4.42643 7.7008 4.19723C7.94 3.96803 8.32 3.97643 8.5492 4.21603L12.1852 8.01562L8.5492 11.8152Z' fill='%232E3790'/%3E%3C/g%3E%3C/svg%3E%0A");
        float: right;
      }

      ${respondTo.md`
        color: ${ink2};
        border-bottom: 0.5px solid rgba(0, 0, 0, 0.40);
        border-radius: 5px;
        box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.25);
        height: 34px;
        padding: 5px 10px 5px 10px;
        margin-top: 2px;

        &:hover {
          background: ${tintsBlue};
        }

        &:after {
          content: '';
          float: unset;
        }
  
      `}
    }
  }

  .indication-dropdown {
    cursor: pointer;
    border-radius: 5px;
    border: 1px solid ${ink2};
    background: ${white};
    padding: 5px 7px 5px 7px;
    width: 230px;
    height: 34px;
    text-align: left;
    font-size: 18px;
    color: ${ink2};
    

    &:hover {
      background: ${tintsBlue};
    }

    &:after {
      content: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='graphic-arrows'%3E%3Cpath id='Mask' d='M13.727 8.81322L8.46079 3.2865C8.09681 2.9045 7.50823 2.9045 7.14811 3.2865L6.27299 4.20491C5.909 4.5869 5.909 5.2046 6.27299 5.58253L10.0058 9.5L6.27299 13.4175C5.909 13.7995 5.909 14.4172 6.27299 14.7951L7.14811 15.7135C7.5121 16.0955 8.10068 16.0955 8.46079 15.7135L13.727 10.1868C14.091 9.81291 14.091 9.19522 13.727 8.81322Z' fill='%23343F99'/%3E%3C/g%3E%3C/svg%3E%0A");
      float: right;
      margin-top: 2px;
    }

    &[data-state="true"].main {
      display: none;
    }

    &.back {
      display: inline-block;
      height: unset;
      border: unset;
      border-radius: unset;

      background: ${white};

      color: ${charcoal2};
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;

      width: 100%;
      margin-left: 0px;
      padding: 0px 10px 15px 10px;

      &:after {
        content: url("data:image/svg+xml,%3Csvg width='9' height='15' viewBox='0 0 9 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='Components/Icons/Simple/Carat-Left'%3E%3Cpath id='Vector' d='M3.30726e-05 7.50014L6.3872 14.1747C6.78983 14.5956 7.45664 14.6096 7.87754 14.2077C8.29843 13.8051 8.31248 13.1383 7.91056 12.7174L2.82697 7.50014L7.91056 2.2829C8.31318 1.86201 8.29843 1.19519 7.87754 0.792564C7.45735 0.389942 6.78983 0.4047 6.3872 0.825591L3.30726e-05 7.50014Z' fill='%232E3790'/%3E%3C/g%3E%3C/svg%3E%0A");
        float: left; 
        margin-right: 21px;
      }
    }

    ${respondTo.md`
      &.main:after {
        transform: rotate(0deg);
        transform-origin: center;
        margin-top: 2px;
        height: 20px;
      }

      &[data-headlessui-state="open"].main, &[data-state="true"].main{
        display: unset;
        background: ${tintsBlue};
      }

      &[data-state="false"].main:after{
        transform: rotate(0deg);
      }

      &[data-state="true"].main:after{
        transform: rotate(180deg);
      }

      &.main {
        display: unset;
      }

      &.back {
        display: none;
      }

      &:after {
        content: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='graphic-arrows'%3E%3Cpath id='Mask' d='M9.81322 13.727L4.2865 8.46079C3.9045 8.09681 3.9045 7.50823 4.2865 7.14811L5.20491 6.27299C5.5869 5.909 6.2046 5.909 6.58253 6.27299L10.5 10.0058L14.4175 6.27299C14.7995 5.909 15.4172 5.909 15.7951 6.27299L16.7135 7.14811C17.0955 7.5121 17.0955 8.10068 16.7135 8.46079L11.1868 13.727C10.8129 14.091 10.1952 14.091 9.81322 13.727Z' fill='%23343F99'/%3E%3C/g%3E%3C/svg%3E");
      }
    `}
  }
`

const NavigationDropDown = ({handleIndicationDropdownChange, navItem, pageKey}) => {

  let DropdownDefaultText = "";
  const [dropdownState, setDropdownState] = useState(null);

  if(pageKey === 'rcc') {
    DropdownDefaultText = 'Advanced RCC'
  }
  else if (pageKey === 'vhl') {
    DropdownDefaultText = 'VHL Disease'
  } 
  else {
    DropdownDefaultText = navItem.label;
  }

  const handleDropdown = (e) => {
    e.preventDefault();
    setDropdownState(!dropdownState);
  };

  return (
      <ListboxContainer>
        <Listbox onChange={handleIndicationDropdownChange}>
        
          <Listbox.Button  onClick={handleDropdown} className="indication-dropdown main" data-state={dropdownState}>{DropdownDefaultText}</Listbox.Button>
         
          { dropdownState &&
          
            <>
              <Listbox.Label onClick={handleDropdown}  className="indication-dropdown back">{'Back'}</Listbox.Label>
              <Listbox.Options static>
                {navItem.options.map((option, i) => (
                  <Listbox.Option key={i} value={option}>
                      {option.label}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </>
          }
        </Listbox> 
      </ListboxContainer>
  )
};

export default NavigationDropDown;