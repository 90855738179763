const safetyCopy = {
  safetyJobCode: 'US-BEL-00472 08/22', // Specific to safety panel

  title: 'Selected Safety Information',
  for: 'WELIREG™ (belzutifan)',

  warningList: {
    title: 'WARNING: EMBRYO-FETAL TOXICITY',
    items: [
      'Exposure to WELIREG during pregnancy can cause embryo-fetal harm.',
      'Verify pregnancy status prior to the initiation of WELIREG.',
      'Advise patients of these risks and the need for effective non-hormonal contraception as WELIREG can render some hormonal contraceptives ineffective.',
    ],
  },

  sections: [
    {
      title: 'Anemia',
      items: [
        'WELIREG can cause severe anemia that can require blood transfusion.<ul class="sub-list"><li>Monitor for anemia before initiation of, and periodically throughout, treatment. Transfuse patients as clinically indicated. For patients with hemoglobin <8 g/dL, withhold WELIREG until ≥8 g/dL, then resume at the same or reduced dose or permanently discontinue WELIREG, depending on the severity of anemia. For life-threatening anemia or when urgent intervention is indicated, withhold WELIREG until hemoglobin ≥8 g/dL, then resume at a reduced dose or permanently discontinue WELIREG.</li></ul>',
        'In LITESPARK-004 (N=61), decreased hemoglobin occurred in 93% of patients with VHL disease and 7% had Grade 3 events. Median time to onset of anemia was 31 days (range: 1 day to 8.4 months).',
        'The safety of erythropoiesis-stimulating agents (ESAs) for treatment of anemia in patients with VHL disease treated with WELIREG has not been established.',
        'In LITESPARK-005 (n=372), decreased hemoglobin occurred in 88% of patients with advanced RCC and 29% had Grade 3 events. Median time to onset of anemia was 29 days (range: 1 day to 16.6 months). Of the patients with anemia, 22% received transfusions only, 20% received erythropoiesis-stimulating agents (ESAs) only, and 12% received both transfusion and ESAs.',
      ],
    },
    {
      title: 'Hypoxia',
      items: [
        'WELIREG can cause severe hypoxia that may require discontinuation, supplemental oxygen, or hospitalization.',
        'Monitor oxygen saturation before initiation of, and periodically throughout, treatment. For decreased oxygen saturation with exercise (eg, pulse oximeter <88% or PaO<sub>2</sub> ≤55 mm Hg), consider withholding WELIREG until pulse oximetry with exercise is greater than 88%, then resume at the same or a reduced dose. For decreased oxygen saturation at rest (eg, pulse oximeter <88% or PaO<sub>2</sub> ≤55 mm Hg) or when urgent intervention is indicated, withhold WELIREG until resolved and resume at a reduced dose or discontinue. For life-threatening or recurrent symptomatic hypoxia, permanently discontinue WELIREG. Advise patients to report signs and symptoms of hypoxia immediately to a health care provider.',
        'In LITESPARK-004, hypoxia occurred in 1.6% of patients.',
        'In LITESPARK-005, hypoxia occurred in 15% of patients and 10% had Grade 3 events. Of the patients with hypoxia, 69% were treated with oxygen therapy. Median time to onset of hypoxia was 30.5 days (range: 1 day to 21.1 months).',
      ],
    },
    {
      title: 'Embryo-Fetal Toxicity ',
      items: [
        'Based on findings in animals, WELIREG can cause fetal harm when administered to a pregnant woman.',
        'Advise pregnant women and females of reproductive potential of the potential risk to the fetus. Advise females of reproductive potential to use effective non-hormonal contraception during treatment with WELIREG and for 1 week after the last dose. WELIREG can render some hormonal contraceptives ineffective. Advise male patients with female partners of reproductive potential to use effective contraception during treatment with WELIREG and for 1 week after the last dose.',
      ],
    },
    {
      title: 'Adverse Reactions',
      items: [
        'In LITESPARK-004, serious adverse reactions occurred in 15% of patients, including anemia, hypoxia, anaphylaxis reaction, retinal detachment, and central retinal vein occlusion (1 patient each).',
        'WELIREG was permanently discontinued due to adverse reactions in 3.3% of patients for dizziness and opioid overdose (1.6% each).',
        'Dosage interruptions due to an adverse reaction occurred in 39% of patients. Those which required dosage interruption in >2% of patients were fatigue, decreased hemoglobin, anemia, nausea, abdominal pain, headache, and influenza-like illness.',
        'Dose reductions due to an adverse reaction occurred in 13% of patients. The most frequently reported adverse reaction which required dose reduction was fatigue (7%).',
        'The most common adverse reactions (≥25%), including laboratory abnormalities, that occurred in patients who received WELIREG were decreased hemoglobin (93%), fatigue (64%), increased creatinine (64%), headache (39%), dizziness (38%), increased glucose (34%), and nausea (31%).',
        'In LITESPARK-005, serious adverse reactions occurred in 38% of patients. The most frequently reported serious adverse reactions were hypoxia (7%), anemia (5%), pneumonia (3.5%), hemorrhage (3%), and pleural effusion (2.2%). Fatal adverse reactions occurred in 3.2% of patients who received WELIREG, including sepsis (0.5%) and hemorrhage (0.5%).',
        'WELIREG was permanently discontinued due to adverse reactions in 6% of patients. Adverse reactions which resulted in permanent discontinuation (≥0.5%) were hypoxia (1.1%), anemia (0.5%), and hemorrhage (0.5%).',
        'Dosage interruptions due to an adverse reaction occurred in 39% of patients. Of the patients who received WELIREG, 28% were 65 to 74 years, and 10% were 75 years and over. Dose interruptions occurred in 48% of patients ≥65 years of age and in 34% of younger patients. Adverse reactions which required dosage interruption in ≥2% of patients were anemia (8%), hypoxia (5%), COVID-19 (4.3%), fatigue (3.2%), and hemorrhage (2.2%).',
        'Dose reductions due to an adverse reaction occurred in 13% of patients. Dose reductions occurred in 18% of patients ≥65 years of age and in 10% of younger patients. The most frequently reported adverse reactions which required dose reduction (≥1.0%) were hypoxia (5%) and anemia (3.2%).',
        'The most common adverse reactions (≥25%), including laboratory abnormalities, were decreased hemoglobin (88%), fatigue (43%), musculoskeletal pain (34%), increased creatinine (34%), decreased lymphocytes (34%), increased alanine aminotransferase (32%), decreased sodium (31%), increased potassium (29%), and increased aspartate aminotransferase (27%).',
      ],
    },
    {
      title: 'Drug Interactions',
      items: [
        'Coadministration of WELIREG with inhibitors of UGT2B17 or CYP2C19 increases plasma exposure of belzutifan, which may increase the incidence and severity of adverse reactions. Monitor for anemia and hypoxia and reduce the dosage of WELIREG as recommended.',
        'Coadministration of WELIREG with CYP3A4 substrates decreases concentrations of CYP3A4 substrates, which may reduce the efficacy of these substrates or lead to therapeutic failures. Avoid coadministration with sensitive CYP3A4 substrates. If coadministration cannot be avoided, increase the sensitive CYP3A4 substrate dosage in accordance with its Prescribing Information. Coadministration of WELIREG with hormonal contraceptives may lead to contraceptive failure or an increase in breakthrough bleeding.',
      ],
    },
    {
      title: 'Lactation',
      items: [
        'Because of the potential for serious adverse reactions in breastfed children, advise women not to breastfeed during treatment with WELIREG and for 1 week after the last dose.',
      ],
    },
    {
      title: 'Females and Males of Reproductive Potential',
      items: [
        'WELIREG can cause fetal harm when administered to a pregnant woman. Verify the pregnancy status of females of reproductive potential prior to initiating treatment with WELIREG.',
        'Use of WELIREG may reduce the efficacy of hormonal contraceptives. Advise females of reproductive potential to use effective non-hormonal contraception during treatment with WELIREG and for 1 week after the last dose. Advise males with female partners of reproductive potential to use effective contraception during treatment with WELIREG and for 1 week after the last dose.',
        'Based on findings in animals, WELIREG may impair fertility in males and females of reproductive potential and the reversibility of this effect is unknown.',
      ],
    },
    {
      title: 'Pediatric Use',
      items: [
        'Safety and effectiveness of WELIREG in pediatric patients under 18 years of age have not been established.',
      ],
    },
  ],

  finalWarning: 'Before prescribing WELIREG, please read the accompanying <a target="_blank" href="https://www.merck.com/product/usa/pi_circulars/w/welireg/welireg_pi.pdf">Prescribing Information</a>, including the Boxed Warning about embryo-fetal toxicity. The <a target="_blank" href="https://www.merck.com/product/usa/pi_circulars/w/welireg/welireg_mg.pdf">Medication Guide</a> also is available.',
};


export default safetyCopy;