/* eslint-disable react/no-unknown-property */
import * as React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';


const PageSeo = ({ pageMeta, children }) => {
  return (
    <Helmet>
      <title>{pageMeta ? pageMeta.title : ''}</title>
      <meta name="keywords" content={pageMeta ? pageMeta.keywords : ''} />
      <meta name="description" content={pageMeta ? pageMeta.description : ''} />

      {/* < OneTrust Cookies Consent Notice start for merck.oncology.welireg-hcp-branded.mzr.egplusww.com > */}
      <script src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js" type="text/javascript" data-domain-script={process.env.GATSBY_PRODUCTION_BUILD ? 'dae2db3b-a7be-4550-8c3a-6a927aff312a' : 'ea0ad70f-a627-4b02-a741-2ea221ef9399-test'} ></script>
      <script type="text/javascript">
      {`
        function OptanonWrapper() { } 
      `}
      </script>

      <meta name="google-site-verification" content="GG0114BfKg2V84s7YPgfU9QF4vP-zjBNI9LoETrNRWg" />
    
      <script
        src="https://players.brightcove.net/1905768940001/meAxH3mZK_default/index.min.js">
      </script>
      <script>
      {`
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-5WRH3JW');`
      }
      </script>
      {pageMeta && pageMeta.schemaJsonLD.map((script, i) => <script key={i} type="application/ld+json">{script}</script>)} 

      {/* To support multiple schemas. Make sure that `` exists with {} for any script. */}
      {children}

    </Helmet>
  );
};

export default PageSeo;
